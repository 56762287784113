
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { Role, UserModel } from "@/models/user/user.model";
import UserService from "@/services/user/user.service";

export default mixins(MixinsCommon).extend({
  name: "UserListItemComponent",

  props: {
    item: {
      type: Object as () => UserModel,
      default: () => {
        return null as UserModel | null;
      },
    },
  },
  methods: {
    getRoleColor(roles) {
      if (roles.indexOf(Role.WAIT_USER) > -1) {
        return "orange";
      }
      return "";
    },
    rolesToText(roles) {
      return UserService.rolesToText(roles);
    },
  },
});
