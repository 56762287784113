
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import MixinsStorage from "@/mixins/single/storage";
import ProfileListItemComponent from "@/components/profile/ListItemComponent.vue";
import { StorageProfileView } from "@/types";
import core from "@/core";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import UpdateEvent from "@/models";
import { ComponentProperties } from "@/mixins/component";

export default mixins(MixinsPageForm).extend({
  name: "HomeComponent",
  components: {},
  data: () => ({}),
  mounted() {
    this.$nextTick(async () => {});
  },
  methods: {},
});
