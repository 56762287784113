
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import {
  ConferenceModel,
  ConferencePresentationAreaModel,
  ConferenceScheduleModel,
} from "@/models/conference/conference.model";
import core from "@/core";
import ConferenceScheduleDayComponent from "@/components/conference/ScheduleDayComponent.vue";
import PublicConferenceService from "@/services/conference/public-conference.service";
import UpdateEvent from "@/models";
import ConferenceFilterModal from "@/modals/conference/ConferenceFilterModal.vue";
import { LayoutType, RouteMeta } from "@/router";
import { cloneDeep } from "lodash";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "Schedule",
  components: {
    ConferenceFilterModal,
    ConferenceScheduleDayComponent,
  },
  data: () => ({
    tab: null,
    items: [] as any,
    title: "",
    mapLink: "",
    presentationAreaList: [] as ConferencePresentationAreaModel[],
    filter: {
      input: "",
      selectedItemList: [] as string[],
      totalItemList: [] as string[],
    },
    conferenceList: [] as ConferenceModel[],
    conference: null as ConferenceModel | null,
    scheduleList: [] as ConferenceScheduleModel[],
    mySchedule: false,
    modal: {
      filter: {
        visible: false,
        params: {
          itemList: [] as string[],
          inputText: null as string | null,
          selectedItemList: [] as string[],
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    bottomNav: "schedule",
    ready: false,
    prop: null as any,
  }),

  mounted() {
    this.$nextTick(async () => {
      if (this.$route.path === "/my-schedule") {
        this.bottomNav = "mySchedule";
        this.mySchedule = true;
      } else {
        this.bottomNav = "schedule";
        this.mySchedule = false;
      }

      const meta = this.$route.meta as RouteMeta;
      const layoutList = cloneDeep(meta.layoutList) as LayoutType[];
      layoutList.push(LayoutType.APP_BAR_NAV);
      this.prop = {
        layoutList: layoutList,
        appBarMenu: meta.appBarMenu,
      };

      const conferenceList = (this.conferenceList =
        (await PublicConferenceService.getActiveList()) as ConferenceModel[]);
      //console.log("conferenceList : ", conferenceList);
      if (conferenceList.length > 0) {
        this.conference = conferenceList[0];
        this.appBarChangeMenuVisible("filter", !this.mySchedule);
        this.appBarChangeMenuBadge("filter", "");
      } else {
        this.title = "일정";
        this.appBarChangeMenuVisible("filter", false);
        this.appBarChangeMenuBadge("filter", "");
      }

      this.ready = true;
    });
  },
  watch: {
    mySchedule() {
      if (!this.mySchedule) {
        this.appBarChangeMenuVisible("filter", true);
        if (this.filter.totalItemList.length > 0) {
          this.appBarChangeMenuBadge("filter", String(this.filter.totalItemList.length));
        } else {
          this.appBarChangeMenuBadge("filter", "");
        }
        const items = this.items;
        if (items != null) {
          const tab = this.tab;
          items.forEach((item, index) => {
            item.visible = index === tab;
          });
        }
      } else {
        this.appBarChangeMenuVisible("filter", false);
        const items = this.items;
        if (items != null) {
          items.forEach((item, index) => {
            item.visible = false;
          });
        }
      }
    },
    tab(tab) {
      console.log("changed tab : ", tab);
      const items = this.items;
      if (items != null) {
        items.forEach((item, index) => {
          item.visible = index === tab;
        });
      }
    },
    async conference(conference) {
      this.items = [];
      if (conference != null) {
        this.title = conference.name;

        let address = "";
        if (this.isNotBlank(conference.address1)) {
          address = conference.address1;
          if (this.isNotBlank(conference.address2)) {
            address += " " + conference.address2;
          }
        }
        if (this.app.isMobile && this.isNotBlank(address)) {
          this.mapLink = core.mobile.getGeoLink(address, conference.latitude, conference.longitude);
        }
        // console.log("conference : ", conference);
        const startMoment = core.date.instance(conference.startDate);
        const endMoment = core.date.instance(conference.endDate);
        const diffDays = endMoment.diff(startMoment, "days") + 1;
        // console.log("diffDays : ", diffDays);
        if (diffDays > 0) {
          const items = [] as any;
          const moment = core.date.instance(conference.startDate);
          for (let i = 0; i < diffDays; i++) {
            let amount = 0;
            if (i > 0) amount = 1;
            moment.add(amount, "days");
            // console.log("i : ", i, ", moment : ", moment.format("YYYY-MM-DD"));
            const item = {
              visible: false,
              displayText: moment.format("D"),
              date: moment.format("YYYY-MM-DD"),
            };
            items.push(item);
          }
          // console.log("items : ", items);
          this.items = items;
          // // 같은 날짜 존재할경우 tab 변경
          // const strTodayDate = core.date.instance().format("YYYY-MM-DD");
          // this.items.some((item) => {
          //   item.date === strTodayDate;
          // });
          try {
            const presentationAreaList = (await PublicConferenceService.getPresentationAreaList(
              conference.id
            )) as ConferencePresentationAreaModel[];

            presentationAreaList.sort((a: any, b: any) => {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              }
              return 0;
            });
            this.presentationAreaList = presentationAreaList;
            // console.log("presentationAreaList : ", presentationAreaList);
          } catch (e) {
            console.log(e);
          }

          const params = {
            conferenceId: conference.id,
          } as any;

          try {
            const scheduleList = (this.scheduleList =
              (await PublicConferenceService.getScheduleList(params)) as ConferenceScheduleModel[]);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    "modal.filter.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.filter);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const filter = this.filter;
          const result = event.item;
          const filterText = result.text;
          filter.input = filterText;
          // console.log("filterText : ", filterText);
          // this.inputFilterItem = filterText;
          const filterItems = result.selected;
          filter.selectedItemList = filterItems;

          // console.log("filterItems : ", filterItems);
          // this.selectedFilterItemList = filterItems;

          const totalItemList = [] as string[];
          if (this.isNotBlank(filterText)) {
            totalItemList.push(filterText);
          }
          filterItems.forEach((filter) => {
            totalItemList.push(filter);
          });
          filter.totalItemList = totalItemList;

          if (totalItemList.length > 0) {
            this.appBarChangeMenuBadge("filter", String(totalItemList.length));
          } else {
            this.appBarChangeMenuBadge("filter", "");
          }
        }
      }
    },
  },
  activated() {
    if (this.mySchedule) {
      this.bottomNav = "mySchedule";
    } else {
      this.bottomNav = "schedule";
    }
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "filter") {
        console.log("show filter modal");
        this.showFilterModal();
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    showFilterModal() {
      const modal = this.modal.filter;
      modal.params.inputText = this.filter.input;
      modal.params.selectedItemList = this.filter.selectedItemList;
      // modal.params.itemList = this.presentationAreaList;
      const itemList = (modal.params.itemList = []) as string[];

      this.presentationAreaList.forEach((presentationArea) => {
        itemList.push(presentationArea.name);
      });
      modal.visible = true;
    },
    moveSchedulePage() {
      if (this.mySchedule) {
        const urlParams = {} as any;
        if (this.isNotBlank(this.$route.query.stackKey)) {
          urlParams.stackKey = this.$route.query.stackKey;
        }
        const length = Object.keys(urlParams).length;
        const url = "/schedule" + (length > 0 ? "?" + core.http.objToUrlParams(urlParams) : "");
        history.replaceState("", "", url);
        this.mySchedule = false;
      }
    },
    moveMySchedulePage() {
      if (!this.mySchedule) {
        const urlParams = {} as any;
        if (this.isNotBlank(this.$route.query.stackKey)) {
          urlParams.stackKey = this.$route.query.stackKey;
        }
        const length = Object.keys(urlParams).length;
        const url = "/my-schedule" + (length > 0 ? "?" + core.http.objToUrlParams(urlParams) : "");
        history.replaceState("", "", url);
        this.mySchedule = true;
      }
    },
  },
});
