
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import UserListItemComponent from "@/components/user/ListItemComponent.vue";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import core from "@/core";
import ConferenceService from "@/services/conference/conference.service";
import Constant from "@/store/constant";
import draggable from "vuedraggable";
import {
  ConferenceModel,
  ConferenceRoomImageModel,
  ConferenceRoomModel,
  ConferenceRoomRequestModel,
  ConferenceScheduleModel,
  ConferenceStatusType,
} from "@/models/conference/conference.model";
import ConferenceRoomAddEditModal from "@/modals/conference/ConferenceRoomAddEditModal.vue";
import UpdateEvent from "@/models";
import ConferenceUploadExcelModal from "@/modals/conference/ConferenceUploadExcelModal.vue";
import ScheduleAddEditModal from "@/modals/conference/ScheduleAddEditModal.vue";
import conference from "@/views/mgmt/Conference.vue";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "MgmtConferenceView",
  components: {
    ScheduleAddEditModal,
    ConferenceUploadExcelModal,
    ConferenceRoomAddEditModal,
    UserListItemComponent,
    draggable,
  },

  data: () => ({
    processing: false,
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
    imageList: [] as { fileDetailId: number; dataUri: string }[],
    roomList: [] as ConferenceRoomModel[],
    type: "add",
    conferenceId: 0,
    conference: null as ConferenceModel | null,
    mapLink: "",
    scheduleList: [] as ConferenceScheduleModel[],
    view: {
      room: "",
    },
    modal: {
      conferenceUploadExcel: {
        visible: false,
        params: {
          conferenceId: 0,
        },
        updateEvent: null as null | UpdateEvent,
      },
      scheduleAddEdit: {
        visible: false,
        params: {
          type: "add",
          conferenceId: null as number | null,
          minDate: "",
          maxDate: "",
          schedule: null as ConferenceScheduleModel | null,
          roomList: [] as ConferenceRoomModel[],
        },
        updateEvent: null as null | UpdateEvent,
      },
    },
    csv: false,
    ready: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      const id = Number(this.$route.params.id);
      if (isNaN(id) || id <= 0) {
        await this.notFound();
        return;
      }
      if (this.isNotBlank(this.$route.query.csv)) {
        this.csv = true;
      }
      this.conferenceId = id;

      this.appBarChangeMenuVisible("edit", true);

      await this.getConference();

      await this.getScheduleList();
      this.ready = true;
    });
  },
  watch: {
    "modal.scheduleAddEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.scheduleAddEdit);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const schedule = event.item;
          let exists = false;
          this.scheduleList.some((orgSchedule) => {
            if (orgSchedule.id === schedule.id) {
              core.utils.copy(schedule, orgSchedule);
              // console.log("schedule : ", schedule);
              // console.log("org schedule : ", orgSchedule);
              exists = true;
              return true;
            }
          });
          if (!exists) {
            // console.log("schedule : ", schedule);
            if (schedule.conferenceRoomId != null) {
              this.roomList.some((room) => {
                if (room.id === schedule.conferenceRoomId) {
                  schedule.conferenceRoom = room;
                  return true;
                }
              });
            }
            this.scheduleList.push(schedule);
          }

          this.sortScheduleList();
        } else if (event.result === UpdateEventType.DELETE) {
          const id = event.item;
          this.scheduleList.some((orgSchedule, index) => {
            if (orgSchedule.id === id) {
              this.scheduleList.splice(index, 1);
              return true;
            }
          });
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
    async "modal.conferenceUploadExcel.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.conferenceUploadExcel);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          this.getScheduleList().then(async (scheduleList) => {
            // console.log("scheduleList : ", scheduleList);
            const conference = this.conference as ConferenceModel;
            const updateRooms = [] as ConferenceRoomRequestModel[];
            const roomMap = {} as { [key: string]: ConferenceRoomModel };
            scheduleList.forEach((schedule) => {
              if (schedule.conferenceRoom != null) {
                // console.log("conferenceRoom : ", schedule.conferenceRoom);
                if (roomMap[schedule.conferenceRoom.name] == null) {
                  roomMap[schedule.conferenceRoom.name] = schedule.conferenceRoom;
                  const room = schedule.conferenceRoom;
                  updateRooms.push({
                    id: room.id,
                    index: 0,
                    name: room.name,
                    option: room.option,
                  });
                }
              }
            });
            // console.log("updateRooms : ", updateRooms);
            const list = (await ConferenceService.updateRooms(conference.id, {
              roomList: updateRooms,
            })) as ConferenceRoomModel[];
            this.roomList = [];
            let roomText = "";
            list.forEach((room) => {
              this.roomList.push(room);
              if (roomText.length > 0) {
                roomText += ", ";
              }
              roomText += room.name;
            });
            if (roomText.length > 0) {
              this.view.room = roomText;
            } else {
              this.view.room = "Room 정보가 없습니다";
            }
          });

          const result = event.item;
          const errorMsgList = result.errorMsgList;
          if (errorMsgList != null && errorMsgList.length > 0) {
            // console.log("errorMsgList : ", errorMsgList);
            let errorMsgText = "";
            errorMsgList.forEach((errorMsg: string) => {
              if (errorMsgText.length > 0) errorMsgText += "<br>";
              errorMsgText += errorMsg;
            });
            await core.alert.show({
              title: "알림",
              body: errorMsgText,
            });
          }
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
  },
  async activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.DELETE) {
        this.goBack(UpdateEventType.DELETE, event.item);
      } else {
        this.ready = false;
        await this.getConference();

        await this.getScheduleList();
        this.ready = true;
      }
    }
  },
  methods: {
    statusToHtml() {
      const conference = this.conference as ConferenceModel;
      if (conference.status === ConferenceStatusType.WAIT) {
        return "<span class='orange--text'>대기</span>";
      } else if (conference.status === ConferenceStatusType.ACTIVE) {
        return "<span class='green--text'>활성</span>";
      } else {
        return "종료";
      }
    },
    async getConference() {
      try {
        const conferenceId = this.conferenceId;
        const conference = await ConferenceService.get(conferenceId);
        // console.log("conference : ", conference);
        this.conference = conference;

        let address = "";
        if (this.isNotBlank(conference.address1)) {
          address = conference.address1;
          if (this.isNotBlank(conference.address2)) {
            address += " " + conference.address2;
          }
        }
        if (this.app.isMobile && this.isNotBlank(address)) {
          this.mapLink = core.mobile.getGeoLink(address, conference.latitude, conference.longitude);
        }

        const roomImages = (await ConferenceService.getRoomImages(
          conferenceId
        )) as ConferenceRoomImageModel[];
        // console.log("roomImages : ", roomImages);
        roomImages.forEach((roomImage) => {
          this.imageList.push({
            fileDetailId: roomImage.fileDetailId,
            dataUri: roomImage.image.uri,
          });
        });
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
    },
    async getRoomList() {
      const conference = this.conference as ConferenceModel;
      this.roomList = [];
      let roomText = "";
      const roomList = (await ConferenceService.getRooms(conference.id)) as ConferenceRoomModel[];
      roomList.forEach((room) => {
        this.roomList.push(room);
        if (roomText.length > 0) {
          roomText += ", ";
        }
        roomText += room.name;
      });
      if (roomText.length > 0) {
        this.view.room = roomText;
      } else {
        this.view.room = "Room 정보가 없습니다";
      }
      return this.roomList;
    },
    async getScheduleList() {
      const roomList = await this.getRoomList();

      const conference = this.conference as ConferenceModel;
      this.scheduleList = [];
      const params = {
        conferenceId: conference.id,
      };
      const scheduleList = (await ConferenceService.getScheduleList(
        params
      )) as ConferenceScheduleModel[];
      scheduleList.forEach((schedule) => {
        if (schedule.conferenceRoom == null) schedule.conferenceRoom = null;
        if (schedule.conferenceRoomId == null) schedule.conferenceRoomId = null;
        this.scheduleList.push(schedule);
      });
      return scheduleList;
    },
    sortScheduleList() {
      this.scheduleList.sort((a: any, b: any) => {
        const aMoment = core.date.instance(a.startAt);
        const bMoment = core.date.instance(b.startAt);
        if (aMoment.isBefore(bMoment)) {
          return -1;
        }
        return 1;
      });
    },
    scheduleDateText(item) {
      const startDate = core.date.instance(item.startAt).format("YYYY.MM.DD(ddd)");
      const endDate = core.date.instance(item.endAt).format("YYYY.MM.DD(ddd)");
      const startTime = core.date.instance(item.startAt).format("a hh:mm");
      const endTime = core.date.instance(item.endAt).format("a hh:mm");

      if (item.allDay) {
        if (startDate == endDate) {
          return startDate;
        } else {
          return startDate + " ~ " + endDate;
        }
      } else {
        if (startDate == endDate) {
          return `${startDate} ${startTime} ~ ${endTime}`;
        } else {
          return `${startDate} ${startTime}` + " ~ " + `${endDate} ${endTime}`;
        }
      }
    },
    downloadCsv() {
      console.log("download");
      if (this.scheduleList != null) {
        const conference = this.conference as ConferenceModel;
        const filename = `${conference.name}_${core.date.instance().format("YYYYMMDD_HHMM")}.csv`;
        // const titleList = ["시간", "온도", "습도", "메탄"];
        const contentList = [] as any;
        {
          const columns = [] as any;
          columns.push("날짜", "시작 시간", "종료 시간", "발표분야", "논문제목", "ROOM");
          for (let i = 1; i <= 8; i++) {
            columns.push(`제${i}저자 소속`);
            columns.push(`제${i}저자`);
          }
          contentList.push(columns.join(","));
        }
        this.scheduleList.forEach((schedule) => {
          const columns = [] as string[];
          columns.push(core.date.instance(schedule.startAt).format("YYYY-MM-DD"));
          columns.push(core.date.instance(schedule.startAt).format("HH:MM"));
          columns.push(core.date.instance(schedule.endAt).format("HH:MM"));
          columns.push(schedule.presentationArea);
          columns.push(schedule.title);
          let roomName = "";
          if (schedule.conferenceRoom != null) {
            roomName = schedule.conferenceRoom.name;
          }
          columns.push(roomName);

          for (let i = 1; i <= 8; i++) {
            const val = schedule["author" + i];
            if (val != null) {
              columns.push("");
              columns.push(val);
            } else {
              columns.push("");
              columns.push("");
            }
          }
          contentList.push(columns.join(","));
        });
        this.saveCsv(contentList.join("\n"), filename);
      }
    },
    saveCsv(csv, filename) {
      // 한글 처리를 해주기 위해 BOM 추가
      const BOM = "\uFEFF";
      csv = BOM + csv;

      const csvFile = new Blob([csv], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    showUploadModal() {
      const conference = this.conference as ConferenceModel;
      const modal = this.modal.conferenceUploadExcel;
      modal.params.conferenceId = conference.id;
      modal.visible = true;
    },
    showAddSchedule() {
      const conference = this.conference as ConferenceModel;
      const modal = this.modal.scheduleAddEdit;
      modal.params.type = "add";
      modal.params.conferenceId = conference.id;
      modal.params.roomList = this.roomList;
      modal.params.minDate = conference.startDate;
      modal.params.maxDate = conference.endDate;
      modal.visible = true;
    },
    showEditSchedule(schedule: ConferenceScheduleModel) {
      const conference = this.conference as ConferenceModel;
      const modal = this.modal.scheduleAddEdit;
      modal.params.type = "edit";
      modal.params.conferenceId = conference.id;
      modal.params.roomList = this.roomList;
      modal.params.schedule = schedule;
      modal.params.minDate = conference.startDate;
      modal.params.maxDate = conference.endDate;
      modal.visible = true;
    },
    addressToText(conference: ConferenceModel) {
      if (this.isNotBlank(conference.address1 != null)) {
        let address = conference.address1;
        if (this.isNotBlank(conference.address2)) {
          address += ` ${conference.address2}`;
        }
        return address;
      }
      return "";
    },
    async appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "edit") {
        const conference = this.conference as ConferenceModel;
        await this.$router.push({
          path: "/mgmt/conference/edit",
          query: {
            id: String(conference.id),
          },
        });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },

    getFilename(uri: string) {
      if (uri != null) {
        const pos = uri.lastIndexOf("/");
        if (pos > -1) {
          return uri.substring(pos + 1, uri.length);
        }
      }
      return uri;
    },
  },
});
