import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import { UserModel } from "@/models/user/user.model";
import { NotificationModel } from "@/models/user/notification.model";
import { ProfileModel } from "@/models/profile/profile.model";
import { ConferenceUserScheduleModel } from "@/models/conference/conference-user-schedule.model";

/**
 * interface
 */
export interface ErrorResponse {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

// Store root state
export interface RootState {
  topToolbar: TopToolbarState;
  app: AppState;
  auth: AuthState;
  storage: StorageState;
}

// Store modules state
export interface TopToolbarState {
  title: string;
  dark: boolean;
  clazz: string;
  elevation: number;
  color: string;
  changedStyle: boolean;
}

export interface Filter {
  type: string;
  filter: any;
}

export interface SessionItem {
  type: string;
  items: any;
}

export interface StorageItem {
  type: string;
  items: any;
}

export interface StorageState {
  filters: Map<string, any>;
  sessionItems: Map<string, any>;
  storageItems: Map<string, any>;
}

export interface AppState {
  version: string;
  name: string;
  size: WindowSize;
  routerBeforeTask: RouterBeforeTask;
  modal: {
    alert: AlertModal;
  };
  showNav: boolean;
  bottomNav: string;
  path: string;
  isApp: boolean;
  isMobile: boolean;
  isMobileSize: boolean;
  webSocketConnected: boolean;
  data: {
    ready: boolean;
    notificationList: NotificationModel[];
    userSchedule: {
      ready: boolean;
      first: boolean;
      list: ConferenceUserScheduleModel[];
    };
  };
}

export interface AuthState {
  ready: boolean;
  user: UserModel | null;
  sessionCheckTime: Date | null;
  mobileToken: string | null;
  uuid: string | null;
  systemProperties: MobileSystemProperties | null;
  mobileSetting: MobileSetting | null;
}

export interface MobileSetting {
  test: boolean;
}

export interface MobileSystemProperties {
  timezone: string;
  locale: string;
  manufacturer: string;
  model: string;
  product: string;
  hardware: string;
  os: string;
  versionSdk: number;
  versionRelease: string;
  deviceToken: string;
  packageName: string;
  versionCode: number;
  versionName: string;
}

export interface WindowSize {
  width: number;
  height: number;
}

export interface AlertModal {
  visible: boolean;
  title: string;
  body: string;
  showCancelButton: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
  promise: {
    resolve: any;
    reject: any;
  };
  allowBackCloseEvent: boolean;
}

export interface TableRequest {
  draw: number;
  start: number;
  length: number;
  orderColumnName: string;
  order: TableOrder;
  searchColumns?: any;
}

export interface TableResponse<T> {
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  data: T[];
}

export interface AppBarMenu {
  icon: AppBarIconMenu | undefined | null;
  more: AppBarMoreMenu | null | undefined;
}

export interface AppBarMoreMenu extends AppBarIconMenu {
  width: number;
}

export interface AppBarIconMenu {
  visible: boolean;
  list: AppBarMenuItem[];
}

export interface AppBarMenuItem {
  icon: string;
  text: string;
  id: string;
  visible: boolean;
  clickEvent: boolean;
  badge: boolean;
  badgeContent: string | undefined;
}

/**
 * enum
 */

export enum UpdateEventType {
  RELOAD = "reload",
  FILTER = "filter",
  DELETE = "delete",
  UPDATE = "update",
  CONFIRM = "confirm",
  CANCEL = "cancel",
  PAGE = "page",
  TABLE = "table",
  EVENT = "event",
  DEACTIVATE = "deactivate",
}

export enum EntityType {
  USER = "USER",
}

export enum TableOrder {
  ASC = "asc",
  DESC = "desc",
}

/**
 * storage
 */
export interface StorageProfileView {
  profile: ProfileModel;
  date: number;
}
