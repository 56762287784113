export interface Address {
  fullAddress: string;
  address: string;
  buildingName?: string;
  latitude: number;
  longitude: number;
}

const kakao = (window as any).kakao;

export default class CoreKakaoMap {
  options: any = null;
  map: any = null;
  mapOption: any = null;
  centerChangeRequest: Date = new Date(0);
  core: any = null;
  restApiKey: string | null = null;

  constructor(params: any, core, restApiKey: string) {
    if (params == null) return;
    else if (params.callback == null) params.callback = {};

    this.core = core;
    this.restApiKey = restApiKey;

    const me = this as any;

    const options = (this.options = {
      map: {
        element: params.map.element,
        option: params.map.option,
        view: params.map.view,
      },
      callback: {
        click: params.callback.click,
        centerChanged: params.callback.centerChanged,
        dragStart: params.callback.dragStart,
        dragEnd: params.callback.dragEnd,
        zoomStart: params.callback.zoomStart,
        zoomChanged: params.callback.zoomChanged,
      },
    });

    // 위치 선택 종류일 경우
    if (this.options.map.view === "selectLocation") {
      let centerLocationMarker = this.options.map.element.querySelector(".center-location-marker");

      if (centerLocationMarker == null) {
        const div = document.createElement("div");
        div.innerHTML = `<div class="center-location-marker" style="position: absolute;z-index: 10; top: 50%; left: 50%; transform: translateX(-50%) translateY(-95%); color: #ffffff;">
                        <img src="//t1.daumcdn.net/mapjsapi/images/marker.png" alt="중앙">
                    </div>`;
        centerLocationMarker = div.querySelector(".center-location-marker");
        this.options.map.element.appendChild(centerLocationMarker);
      }
    }

    const map = (this.map = new kakao.maps.Map(options.map.element, options.map.option));
    this.mapOption = this.options.map.option;

    if (this.options.callback.click) {
      kakao.maps.event.addListener(map, "click", function (e) {
        if (options.callback.click != null) {
          options.callback.click(e);
        }
      });
    }

    if (this.options.callback.centerChanged) {
      kakao.maps.event.addListener(map, "center_changed", function () {
        if (options.callback.centerChanged != null) {
          const curDate = new Date();
          if (curDate.getTime() - me.centerChangeRequest.getTime() < 500) {
            return;
          }

          const latLng = map.getCenter();
          options.callback.centerChanged({
            latitude: latLng.getLat(),
            longitude: latLng.getLng(),
            level: map.getLevel(),
          });
        }
      });
    }

    if (options.callback.dragStart) {
      kakao.maps.event.addListener(map, "dragstart", function () {
        if (options.callback.dragStart != null) {
          options.callback.dragStart();
        }
      });
    }

    if (options.callback.dragEnd) {
      kakao.maps.event.addListener(map, "dragend", function () {
        if (options.callback.dragEnd != null) {
          const latLng = map.getCenter();
          options.callback.dragEnd({
            latitude: latLng.getLat(),
            longitude: latLng.getLng(),
            level: map.getLevel(),
          });
        }
      });
    }

    if (options.callback.zoomStart) {
      kakao.maps.event.addListener(map, "zoom_start", function () {
        if (options.callback.zoomStart != null) {
          options.callback.zoomStart();
        }
      });
    }

    if (options.callback.zoomChanged) {
      kakao.maps.event.addListener(map, "zoom_changed", function () {
        if (options.callback.zoomChanged != null) {
          const latLng = map.getCenter();
          options.callback.zoomChanged({
            latitude: latLng.getLat(),
            longitude: latLng.getLng(),
            level: map.getLevel(),
          });
        }
      });
    }
  }

  setCenterAndLevel(lat: number, lng: number, level: number) {
    const me = this as any;
    me.centerChangeRequest = new Date();
    const latLng = this.newLatLng(lat, lng);
    me.mapOption.center = latLng;
    me.map.setCenter(latLng);
    me.map.setLevel(level);
  }

  setCenter(lat: number, lng: number) {
    const me = this as any;
    me.centerChangeRequest = new Date();
    const latLng = this.newLatLng(lat, lng);
    me.mapOption.center = latLng;
    me.map.setCenter(latLng);
  }

  relayout() {
    const me = this as any;
    me.centerChangeRequest = new Date();
    me.map.relayout();
    me.map.setCenter(me.mapOption.center);
    me.map.setLevel(me.mapOption.level);
  }

  newLatLng(lat: number, lng: number) {
    return new kakao.maps.LatLng(lat, lng);
  }

  addMarker(lat: number, lng: number) {
    const me = this as any;
    const position = new kakao.maps.LatLng(lat, lng);

    const marker = new kakao.maps.Marker({
      map: me.map,
      position: position,
    });
    return marker;
  }

  addMarkerAndOverlay(lat: number, lng: number, text: string, clickCallback: any) {
    const me = this as any;

    const position = new kakao.maps.LatLng(lat, lng);

    const marker = new kakao.maps.Marker({
      map: me.map,
      position: position,
    });

    const content = '<div class="scc-dot-overlay">' + text + "</div>";

    const overlay = new kakao.maps.CustomOverlay({
      map: me.map,
      position: position,
      clickable: false,
      content: content,
      xAnchor: 0.5,
      yAnchor: 2.8,
    });
    marker.overlay = overlay;

    if (clickCallback != null) {
      kakao.maps.event.addListener(marker, "click", clickCallback);
    }
    return marker;
  }

  clearMarker(marker: any) {
    if (marker != null) {
      if (marker.overlay != null) {
        marker.overlay.setMap(null);
      }
      marker.setMap(null);
    }
  }

  async changeAddressByLocation(latitude: number, longitude: number, isLoading: boolean) {
    if (isLoading) {
      this.showLoading("주소 가져오는중...");
    }
    try {
      const result: any = await this.findAddressByLatAndLng(latitude, longitude);
      let fullAddress = result.address;
      if (result.buildingName != null && result.buildingName.length > 0) {
        fullAddress += ` (${result.buildingName})`;
      }
      if (isLoading) {
        this.hideLoading();
      }
      return {
        fullAddress: fullAddress,
        address: result.address,
        buildingName: result.buildingName,
        latitude: latitude,
        longitude: longitude,
      } as Address;
      // me.data.centerMarkerAddressText = fullAddress;
    } catch (reason: any) {
      if (isLoading) {
        this.hideLoading();
      }
      this.core.alert.show({
        title: "알림",
        body: reason.message,
      });
    }
    return null;
  }

  findAddressByLatAndLng(latitude: number, longitude: number) {
    return new Promise((resolve: any, reject) => {
      const geocoder = new kakao.maps.services.Geocoder();

      const func = (response: any, status: any) => {
        if (status === kakao.maps.services.Status.OK) {
          const result = {
            address: null,
            buildingName: null,
          };
          if (response[0].road_address != null) {
            result.address = response[0].road_address.address_name;
            result.buildingName = response[0].road_address.building_name;
          } else {
            result.address = response[0].address.address_name;
          }
          resolve(result);
        } else {
          reject({ message: "선택된 위치 주소를 가져올 수 없습니다" });
        }
      };

      geocoder.coord2Address(longitude, latitude, func);
    });
  }

  searchAddress(address: string) {
    return new Promise((resolve: any, reject) => {
      this.core.http
        .get(
          "//dapi.kakao.com/v2/local/search/address.json",
          {
            headers: {
              Authorization: `KakaoAK ${this.restApiKey}`,
            },
          },
          { query: address }
        )
        .then((data: any) => {
          if (data.documents.length > 0) {
            resolve({
              address: address,
              latitude: data.documents[0].y,
              longitude: data.documents[0].x,
            });
          } else {
            reject({ message: "위경도 좌표를 가져올 수 없습니다" });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  searchKeyword(request: any) {
    return new Promise((resolve: any, reject) => {
      if (request == null || request.query == null || request.query.trim().length == 0) {
        reject({ message: "파라미터 오류" });
        return;
      } else if (request.size > 15) {
        request.size = 15;
      }
      this.core.http
        .get(
          "//dapi.kakao.com/v2/local/search/keyword.json",
          {
            headers: {
              Authorization: `KakaoAK ${this.restApiKey}`,
            },
          },
          request
        )
        .then((data: any) => {
          if (data.documents.length > 0) {
            const list = [] as any;
            data.documents.forEach((data: any) => {
              list.push({
                latitude: data.y,
                longitude: data.x,
                placeUrl: data.place_url,
                placeName: data.place_name,
                phone: data.phone,
                id: data.id,
                distance: data.distance,
                categoryName: data.category_name,
                categoryGroupName: data.category_group_name,
                categoryGroupCode: data.category_group_code,
                addressName: data.address_name,
                roadAddressName: data.road_address_name,
              });
            });
            const result = {
              list: list,
              meta: {
                isEnd: data.meta.is_end,
                pageableCount: data.meta.pageable_count,
                totalCount: data.meta.total_count,
              },
            };
            resolve(result);
          } else {
            reject({ message: "위경도 좌표를 가져올 수 없습니다" });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  showLoading(msg: string) {
    this.core.loader.show(msg);
  }

  hideLoading() {
    this.core.loader.hide();
  }
}
