
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import {
  ConferenceModel,
  ConferenceScheduleDisplayModel,
  ConferenceScheduleModel,
} from "@/models/conference/conference.model";
import core from "@/core";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/body-scroll";
import PublicConferenceService from "@/services/conference/public-conference.service";
import { cloneDeep } from "lodash";
import PublicUserScheduleService from "@/services/conference/public-user-schedule.service";
import ColorItems from "@/data/color-items";

export interface ListItemModel {
  title: string | null;
  schedule: ConferenceScheduleDisplayModel | null;
}

export default mixins(MixinsScroll, MixinsCommon).extend({
  name: "ConferenceScheduleDayComponent",

  props: {
    date: {
      type: String,
      default: "2023-04-05",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    mySchedule: {
      type: Boolean,
      default: false,
    },
    filterList: {
      type: Array,
      default: [] as string[],
    },
    scheduleList: {
      type: Array,
      default: [] as ConferenceScheduleModel[],
    },
  },
  data: () => ({
    ripple: true,
    filterScheduleList: [] as ConferenceScheduleDisplayModel[],
    presentationAreaList: [] as string[],
    presentationAreaColorMap: {} as {
      [key: string]: { color: string; hex: string; text: string; dark: boolean };
    },
    list: [] as ListItemModel[],
    ready: false,
    visibleFilterItemLength: 20,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.changedUserSchedule();
      this.ready = true;
    });
  },
  watch: {
    // presentationAreaFilterList() {
    //   console.log("changed filter List");
    //   this.changedUserSchedule();
    // },
    "scroll.scrollTop"(scrollTop) {
      const body = this.$refs.body as any;
      if (body != null) {
        this.changeScrollTop(scrollTop, body.scrollHeight, body.clientHeight);
      } else {
        console.log("not found body");
      }
    },
    scheduleList() {
      console.log("changed scheduleList");
      this.changedUserSchedule();
    },
    filterList() {
      console.log("changed filter List");
      this.changedUserSchedule();
    },
    visible(visible) {
      if (visible) {
        this.changedUserSchedule();
        if (this.scroll.scrollTop > 0) {
          this.setScrollTop(this.scroll.scrollTop);
        }
      }
    },
    mySchedule(mySchedule) {
      this.changedUserSchedule();
      this.setScrollPosition(ScrollPosition.TOP);
    },
    "app.data.userSchedule.ready"() {
      console.log("change auth data userScheduleList");
      this.changedUserSchedule();
    },
  },

  activated() {
    // console.log("activated");
  },
  methods: {
    changeScrollTop(scrollTop: number, scrollHeight: number, clientHeight: number) {
      // 이벤트 처리 높이
      const eventHeight = Number(clientHeight / 5);
      // console.log("eventHeight : " + eventHeight);

      // 스크롤 최대 높이
      const scrollMaxTop = scrollHeight - clientHeight;
      // console.log("scrollMaxTop : ", scrollMaxTop);
      // const scrollPercentage = (100 * scrollTop) / (scrollHeight - clientHeight);
      if (scrollMaxTop - scrollTop < eventHeight) {
        this.visibleFilterItemLength += 20;
      }
    },
    getStringValue(val: string | null) {
      if (val != null) return val;
      return "";
    },
    getPresentationAreaColorRgba(presentationArea): string {
      if (this.mySchedule) {
        const color = this.presentationAreaColorMap[presentationArea];
        // console.log("presentationArea : ", presentationArea, ", color : ", color);
        if (color != null) {
          const rgb = core.color.hexToRgb(color.hex);
          return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`;
        }
      }
      return "#fff";
    },
    getPresentationAreaColor(presentationArea): string {
      const color = this.presentationAreaColorMap[presentationArea];
      // console.log("presentationArea : ", presentationArea, ", color : ", color);
      if (color != null) {
        return color.color;
      }
      return "blue-grey lighten-5";
    },
    getPresentationAreaDark(presentationArea): boolean {
      const color = this.presentationAreaColorMap[presentationArea];
      // console.log("presentationArea : ", presentationArea, ", color : ", color);
      if (color != null) {
        return color.dark;
      }
      return false;
    },
    getColor(index): { color: string; hex: string; text: string; dark: boolean } {
      if (ColorItems.length > index) {
        index = index % ColorItems.length;
      }
      // console.log("index : ", index);
      const color = ColorItems[index];
      if (color == null) return { color: "teal", hex: "#4CAF50", text: "그린", dark: false };
      return color;
    },
    changedUserSchedule() {
      const filterScheduleList = (this.filterScheduleList = [] as ConferenceScheduleDisplayModel[]);
      const userScheduleData = this.app.data.userSchedule;

      const date = this.date;
      const visibleDate = date === "all";
      const scheduleList = [] as ConferenceScheduleDisplayModel[];
      this.scheduleList.forEach((_schedule) => {
        const schedule = _schedule as ConferenceScheduleDisplayModel;
        let exists = false;
        if (userScheduleData.ready) {
          userScheduleData.list.some((userSchedule) => {
            if (userSchedule.scheduleId === schedule.id) {
              exists = true;
              schedule.userSchedule = userSchedule;
              return true;
            }
          });
        }
        if (!exists) {
          schedule.userSchedule = null;
        }
        if (
          visibleDate ||
          (!visibleDate && date === core.date.instance(schedule.startAt).format("YYYY-MM-DD"))
        ) {
          scheduleList.push(schedule);
        }
      });
      // const filterList = this.presentationAreaFilterList;
      const filterList = this.filterList;

      const checkFilter = filterList != null && filterList.length > 0;
      const filterMap = {} as any;
      if (filterList != null) {
        filterList.forEach((filter) => {
          filterMap[String(filter)] = true;
        });
      }

      const dateMap = {} as any;
      const dateList = [] as any;
      const presentationAreaMap = {} as { [key: number]: ConferenceScheduleDisplayModel[] };
      scheduleList.forEach((schedule) => {
        // console.log("startAt : ", schedule.startAt);
        if (this.mySchedule) {
          if (schedule.userSchedule) {
            // list.push({ title: null, schedule: schedule });
            filterScheduleList.push(schedule);
            if (presentationAreaMap[schedule.presentationArea] == null) {
              presentationAreaMap[schedule.presentationArea] = [];
            }
            presentationAreaMap[schedule.presentationArea].push(schedule);
            const date = core.date.instance(schedule.startAt).format("YYYY-MM-DD");
            if (dateMap[date] == null) {
              dateMap[date] = true;
              dateList.push(date);
            }
          }
        } else {
          let addItem = false;
          if (checkFilter) {
            if (filterList.length > 0) {
              // filter ....?

              let text =
                schedule.title +
                " " +
                schedule.presentationArea +
                " " +
                this.getStringValue(schedule.author1) +
                " " +
                this.getStringValue(schedule.author2) +
                " " +
                this.getStringValue(schedule.author3) +
                " " +
                this.getStringValue(schedule.author4) +
                " " +
                this.getStringValue(schedule.author5) +
                " " +
                this.getStringValue(schedule.author6) +
                " " +
                this.getStringValue(schedule.author7) +
                " " +
                this.getStringValue(schedule.author8);

              if (schedule.conferenceRoom != null) {
                text += " " + schedule.conferenceRoom.name;
              }
              filterList.some((filter) => {
                if (text.indexOf(String(filter)) > -1) {
                  addItem = true;
                  return true;
                }
              });
            }
            // if (this.isNotBlank(schedule.presentationArea)) {
            //   if (filterMap[schedule.presentationArea]) {
            //     addItem = true;
            //   }
            // } else {
            //   addItem = true;
            // }
          } else {
            addItem = true;
          }
          if (addItem) {
            // list.push({ title: null, schedule: schedule });
            filterScheduleList.push(schedule);
            if (presentationAreaMap[schedule.presentationArea] == null) {
              presentationAreaMap[schedule.presentationArea] = [];
            }
            presentationAreaMap[schedule.presentationArea].push(schedule);
            const date = core.date.instance(schedule.startAt).format("YYYY-MM-DD");
            if (dateMap[date] == null) {
              dateMap[date] = true;
              dateList.push(date);
            }
          }
        }
      });

      // console.log("presentationAreaMap : ", presentationAreaMap);
      // console.log("dateList : ", dateList);
      const list = (this.list = [] as ListItemModel[]);
      if (!this.mySchedule) {
        const presentationAreaList = [] as string[];
        for (const key of Object.keys(presentationAreaMap)) {
          presentationAreaList.push(key);
        }
        presentationAreaList.sort((a: any, b: any) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        });
        this.presentationAreaList = presentationAreaList;
        presentationAreaList.forEach((presentationArea) => {
          let first = true;
          const scheduleList = presentationAreaMap[presentationArea];
          scheduleList.forEach((schedule) => {
            if (first) {
              list.push({ title: presentationArea, schedule: null });
              first = false;
            }
            list.push({ title: null, schedule: schedule });
          });
        });
      } else {
        const presentationAreaList = [] as string[];
        for (const key of Object.keys(presentationAreaMap)) {
          presentationAreaList.push(key);
        }
        presentationAreaList.sort((a: any, b: any) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        });
        this.presentationAreaList = presentationAreaList;
        this.presentationAreaColorMap = {};
        this.presentationAreaList.forEach((presentationArea, index) => {
          this.presentationAreaColorMap[presentationArea] = this.getColor(index);
        });

        this.filterScheduleList.forEach((schedule) => {
          if (visibleDate && this.mySchedule) {
            const date = dateList.shift();
            // console.log("date : ", date);
            const scheduleDate = core.date.instance(schedule.startAt).format("YYYY-MM-DD");
            // console.log("scheduleDate : ", scheduleDate);
            if (scheduleDate === date) {
              const title = core.date.instance(schedule.startAt).format("YYYY년 M월 D일");
              list.push({ title: title, schedule: null });
              // console.log("push : ", date);
            } else {
              dateList.unshift(date);
            }
          }
          list.push({ title: null, schedule: schedule });
        });
      }
    },
    movePage(schedule: ConferenceScheduleModel) {
      if (this.ripple) {
        this.$router.push({ path: `/schedule/${schedule.id}` });
      }
    },
    getTime(at) {
      return core.date.instance(at).format("a h:mm");
    },
    async createUserSchedule(item: ConferenceScheduleDisplayModel) {
      this.ripple = false;

      try {
        core.loader.show();
        const scheduleId = item.id;
        const params = {
          uuid: this.auth.uuid,
          scheduleId: scheduleId,
        };
        const userSchedule = await PublicUserScheduleService.create(params);

        ["alarmTimeMin", "alarmAt"].forEach((key) => {
          if (userSchedule[key] == null) {
            userSchedule[key] = null;
          }
        });
        // console.log("userSchedule : ", userSchedule);

        let exists = false;
        this.app.data.userSchedule.list.some((preUserSchedule, index) => {
          if (preUserSchedule.scheduleId === scheduleId) {
            exists = true;
            core.utils.copy(userSchedule, preUserSchedule);
            return true;
          }
        });
        if (!exists) {
          this.app.data.userSchedule.list.push(userSchedule);
        }
        const scheduleList = this.scheduleList as ConferenceScheduleDisplayModel[];
        scheduleList.some((schedule) => {
          if (schedule.id === scheduleId) {
            schedule.userSchedule = userSchedule;
            return true;
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
      setTimeout(() => {
        this.ripple = true;
      }, 100);
    },
    async deleteUserSchedule(item: ConferenceScheduleDisplayModel) {
      this.ripple = false;
      try {
        core.loader.show();
        const scheduleId = item.id;
        const response = await PublicUserScheduleService.delete(scheduleId, {
          uuid: this.auth.uuid,
        });
        this.app.data.userSchedule.list.some((userSchedule, index) => {
          if (userSchedule.scheduleId === scheduleId) {
            this.app.data.userSchedule.list.splice(index, 1);
            return true;
          }
        });
        const scheduleList = this.scheduleList as ConferenceScheduleDisplayModel[];
        scheduleList.some((schedule) => {
          if (schedule.id === scheduleId) {
            schedule.userSchedule = null;
            return true;
          }
        });
        if (this.mySchedule) {
          this.changedUserSchedule();
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }

      setTimeout(() => {
        this.ripple = true;
      }, 100);
    },
  },
});
