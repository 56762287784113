import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { Filter, RootState, SessionItem, StorageItem, StorageState } from "@/types";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";

const state: StorageState = {
  filters: new Map(),
  sessionItems: new Map(),
  storageItems: new Map(),
};

export const getters: GetterTree<StorageState, RootState> = {};

export const actions: ActionTree<StorageState, RootState> = {
  setFilter({ commit }, params: Filter) {
    const user = store.state.auth.user as UserModel;
    let userId = 0;
    if (user != null) {
      userId = user.id;
    }
    const key = `${userId}_filter_${params.type}`;
    const value = JSON.stringify(params.filter);
    //console.log(`localStorage update item key : ${key}, value : ${value}`);
    localStorage.setItem(key, value);
    commit("filter", { type: key, filter: params.filter });
  },
  getFilter({ commit }, type: string) {
    if (type == null) {
      //console.log("invalid filter type : " + type);
      return null;
    }
    const user = store.state.auth.user as UserModel;
    let userId = 0;
    if (user != null) {
      userId = user.id;
    }
    const key = `${userId}_filter_${type}`;
    const filter = state.filters.get(key);
    if (filter != null) {
      return filter;
    } else {
      //console.log(`localStorage get item key : ${key}`);
      const value = localStorage.getItem(key);
      if (value != null) {
        const filters = JSON.parse(value);
        commit("filter", { type: key, filter: filters });
        return filters;
      }
      return null;
    }
  },
  setSessionItem({ commit }, params: SessionItem) {
    const user = store.state.auth.user as UserModel;
    let userId = 0;
    if (user != null) {
      userId = user.id;
    }

    const key = `${userId}_session_${params.type}`;
    const value = JSON.stringify(params.items);
    //console.log(`sessionStorage update item key : ${key}, value : ${value}`);
    sessionStorage.setItem(key, value);

    commit("session", { type: key, items: params.items });
  },
  getSessionItem({ commit }, type: string) {
    if (type == null) {
      console.log("invalid session type : " + type);
      return null;
    }
    const user = store.state.auth.user as UserModel;
    let userId = 0;
    if (user != null) {
      userId = user.id;
    }
    const key = `${userId}_session_${type}`;
    const items = state.sessionItems.get(key);
    if (items != null) {
      return items;
    } else {
      // console.log(`sessionStorage get item key : ${key}`);
      const value = sessionStorage.getItem(key);
      if (value != null) {
        const items = JSON.parse(value);
        commit("session", items);
        return items;
      } else {
        const items = {};
        store.dispatch("storage/setSessionItem", { type: type, items: items });
        return items;
      }
    }
  },

  setStorageItem({ commit }, params: StorageItem) {
    const user = store.state.auth.user as UserModel;
    let userId = 0;
    if (user != null) {
      userId = user.id;
    }
    //console.log("params : ", params);
    const key = `${userId}_storage_${params.type}`;
    if (params.items == null) {
      localStorage.removeItem(key);
    } else {
      const value = JSON.stringify(params.items);
      //console.log(`storage update item key : ${key}, value : ${value}`);
      localStorage.setItem(key, value);
    }
    commit("storage", { type: key, items: params.items });
  },
  async getStorageItem({ commit }, type: string) {
    if (type == null) {
      console.log("invalid storage type : " + type);
      return null;
    }
    const user = store.state.auth.user as UserModel;
    let userId = 0;
    if (user != null) {
      userId = user.id;
    }
    const key = `${userId}_storage_${type}`;
    const items = state.storageItems.get(key);
    if (items != null) {
      // console.log(`storage get item key : ${key}, value : ${items}`);
      return items;
    } else {
      //console.log(`storage get item key : ${key}`);
      const value = localStorage.getItem(key);
      if (value != null) {
        const items = JSON.parse(value);
        commit("storage", items);
        return items;
      } else {
        const items = {};
        await store.dispatch("storage/setStorageItem", { type: type, items: items });
        // actions.setStorageItem({ commit: commit }, { type: type, items: items });
        commit("storage", items);
        return items;
      }
    }
  },
};

const mutations: MutationTree<StorageState> = {
  filter(state, params: Filter) {
    state.filters.set(params.type, params.filter);
  },
  session(state, params: SessionItem) {
    state.sessionItems.set(params.type, params.items);
  },
  storage(state, params: StorageItem) {
    state.storageItems.set(params.type, params.items);
  },
};

export const storage: Module<StorageState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
