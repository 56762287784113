import "ol/ol.css";
import Projection from "ol/proj/Projection";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import View from "ol/View";
import { getCenter } from "ol/extent";
import { Icon, Style } from "ol/style";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { defaults as ControlDefaults } from "ol/control";
import { defaults as InteractionDefaults } from "ol/interaction";

export interface OpenLayersParam {
  map: {
    element: any;
    image: {
      url: string;
      size: {
        width: number;
        height: number;
      };
    };
    zoom: number;
    minZoom: number;
    maxZoom: number;
    enable: {
      rotate: boolean;
      zoom: boolean;
    };
  };
  callback: {
    click: any;
  };
}

export interface MarkerParam {
  name: string;
  position: {
    x: number;
    y: number;
  };
}

export default class CoreOpenlayersImageMap {
  options: any = null;
  map: Map;
  centerChangeRequest: Date = new Date(0);
  vectorSource: any;
  vectorLayer: any;

  constructor(params: OpenLayersParam) {
    if (params == null) return;
    else if (params.callback == null) params.callback = {} as any;

    const me = this as any;

    const options = (this.options = {
      map: {
        element: params.map.element,
        image: params.map.image,
        zoom: params.map.zoom,
        minZoom: params.map.minZoom,
        maxZoom: params.map.maxZoom,
      },
      callback: params.callback,
    }) as OpenLayersParam;

    const vectorSource = (this.vectorSource = new VectorSource({}));

    const vectorLayer = (this.vectorLayer = new VectorLayer({
      source: vectorSource,
    }));

    const extent = [0, 0, options.map.image.size.width, options.map.image.size.height];
    const projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: extent,
    });
    const enable = params.map.enable;

    const map = (this.map = new Map({
      controls: ControlDefaults({ rotate: enable.rotate, zoom: enable.zoom }),
      interactions: InteractionDefaults({
        altShiftDragRotate: enable.rotate,
        doubleClickZoom: enable.zoom,
        keyboard: enable.zoom,
        mouseWheelZoom: enable.zoom,
        shiftDragZoom: enable.zoom,
        dragPan: enable.zoom,
        pinchRotate: enable.rotate,
        pinchZoom: enable.zoom,
      }),
      layers: [
        new ImageLayer({
          source: new Static({
            url: options.map.image.url,
            projection: projection,
            imageExtent: extent,
          }),
        }),
        vectorLayer,
      ],
      target: options.map.element,
      view: new View({
        projection: projection,
        center: getCenter(extent),
        zoom: options.map.zoom,
        minZoom: options.map.minZoom,
        maxZoom: options.map.maxZoom,
      }),
    }));

    map.getView().on("change:center", function (e) {
      // console.log("e : ", e);
      const position = e.oldValue;
      // console.log("position : ", position);
    });

    // map.on("dblclick", function (e) {
    // });
    map.on("click", function (e) {
      if (options.callback && options.callback.click) {
        options.callback.click(e);
      }
    });
  }

  destroy() {
    if (this.map != null) {
      this.map.dispose();
    }
  }

  setZoom(zoom: number) {
    if (this.map != null) {
      this.map.getView().setZoom(zoom);
    }
  }

  getZoom() {
    if (this.map != null) {
      return this.map.getView().getZoom();
    }
  }

  addMarker(param: MarkerParam): Feature {
    const markerStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        scale: 0.5,
        src: "/img/marker/ic_marker_red.png",
      }),
    });

    const feature = new Feature({
      geometry: new Point([param.position.x, param.position.y]),
      name: param.name,
    });
    feature.setStyle(markerStyle);
    this.vectorSource.addFeature(feature);
    return feature;
  }

  moveMarker(marker: Feature, x: number, y: number) {
    marker.setGeometry(new Point([x, y]));
  }
}
