import { IdUpdateModel } from "@/models/core/type.model";
import { ImageFileModel } from "@/models/file/image-file.model";

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  USER = "USER",
  WAIT_USER = "WAIT_USER",
}

export interface UserModel extends IdUpdateModel {
  username: string;
  email: string;
  name: string;
  phone: string;
  roles: Role[];
  profile: ImageFileModel | null;
}
