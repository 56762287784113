import UserService from "@/services/user/user.service";
import core from "@/core";
import { AuthState, MobileSetting, MobileSystemProperties, RootState } from "@/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import store from "@/store";
import { Role, UserModel } from "@/models/user/user.model";
import { Status, SubscribeType } from "@/core/core-web-socket";
import NotificationService from "@/services/notification/notification.service";
import { cloneDeep } from "lodash";

const state: AuthState = {
  ready: false,
  user: null,
  sessionCheckTime: null,
  mobileToken: null,
  uuid: null,
  systemProperties: null,
  mobileSetting: null,
};

const hasRole = (user: UserModel | null, ...roles: Role[]) => {
  if (user != null && user.roles != null && roles != null) {
    let hasRole = false;
    roles.some((role) => {
      if (user.roles.indexOf(role) > -1) {
        hasRole = true;
        return true;
      }
    });
    return hasRole;
  }
  return false;
};

export const getters: GetterTree<AuthState, RootState> = {
  isWaitUserRole: (theState: AuthState) => {
    return hasRole(theState.user, Role.WAIT_USER);
  },
  isUserRole: (theState: AuthState) => {
    return hasRole(theState.user, Role.USER);
  },
  isManagerRoleLower: (theState: AuthState) => {
    return hasRole(theState.user, Role.USER, Role.MANAGER);
  },
  isManagerRole: (theState: AuthState) => {
    return hasRole(theState.user, Role.MANAGER);
  },
  isManagerRoleHigher: (theState: AuthState) => {
    return hasRole(theState.user, Role.MANAGER, Role.ADMIN);
  },
  isAdminRole: (theState: AuthState) => {
    return hasRole(theState.user, Role.ADMIN);
  },
  user: (theState: AuthState) => {
    return async (reload: boolean | null): Promise<UserModel | null> => {
      if (reload == null) reload = false;

      const curTime = new Date();
      if (theState.sessionCheckTime == null) {
        theState.sessionCheckTime = new Date(0);
      }

      if (!reload) {
        //console.log("call getUser");
        const elapsedTimeMillis = curTime.getTime() - theState.sessionCheckTime.getTime();
        //console.log(elapsedTimeMillis);
        if (elapsedTimeMillis < 300000) return theState.user;
      }

      try {
        theState.sessionCheckTime = curTime;
        const user = await UserService.getMyInfo();
        mutations["login"](theState, user);
        return user;
      } catch (reason: any) {
        // console.log("reason : ", reason);
        if (reason.status === 401) {
          if (core.mobile.isApp()) {
            // console.log("refreshUserToken");
            core.mobile.call({ cmd: "refreshUserToken" });
          }
        }
      } finally {
        theState.ready = true;
      }
      return null;
    };
  },
};

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }, params) {
    const result = await UserService.login(params);
    state.sessionCheckTime = new Date(0);

    const user = await store.getters["auth/user"](true);
    //console.log("user : ", user);
    if (user != null) {
      return user;
    }
    await core.alert.show({
      title: "알림",
      body: "아이디 또는 비밀번호가 올바르지 않습니다(1)",
      confirmButtonText: "확인",
    });
  },
  async logout({ commit }) {
    if (state.user != null) {
      try {
        // delete mobile device info
        //console.log("state.systemProperties : ", state.systemProperties);

        const result = await UserService.logout();
        if (state.systemProperties != null) {
          const params = cloneDeep(state.systemProperties) as any;
          params.crudType = "C";
          await UserService.updateMobileDeviceInfo(params);
        }
        return result;
      } catch (e) {
        console.log(e);
      } finally {
        commit("logout");
      }
    }
  },

  async updateMobileSystemProperties({ commit }, systemProperties: MobileSystemProperties) {
    state.mobileToken = systemProperties.deviceToken;
    // console.log("updateMobileSystemProperties : ", systemProperties);
    // delete mobile device info
    const params = cloneDeep(systemProperties) as any;
    params.crudType = "C";
    try {
      const response = (await UserService.updateMobileDeviceInfo(params)) as any;
      if (response.uuid != null) {
        state.uuid = response.uuid;
      } else {
        console.log("not found uuid : ", response);
      }
      commit("mobileSystemProperties", systemProperties);
    } catch (e) {
      console.log("error : ", e);
    }
  },

  updateMobileSetting({ commit }, mobileSetting: MobileSetting) {
    if (core.mobile.isApp()) {
      // delete mobile device settings
      commit("mobileSetting", mobileSetting);
    }
  },

  async register({ commit }, params) {
    return await UserService.register(params);
  },
};

const mutations: MutationTree<AuthState> = {
  async login(theState, user: UserModel) {
    // console.log("mutations[login]: ", theState, user);
    //console.log("update user info");
    const preUser = state.user;
    theState.sessionCheckTime = new Date();
    theState.user = user;

    if (core.mobile.isApp()) {
      //console.log("call cookieSync");
      core.mobile.call({ cmd: "cookieSync" });
      if (preUser == null) {
        //console.log("call systemProperties");
        core.mobile.call({ cmd: "getSystemProperties" });
        // core.mobile.call({ cmd: "refreshUserToken" });
        // core.mobile.call({ cmd: "getMobileSetting" });
      }
    } else {
      //console.log("state.mobileToken : ", state.mobileToken);
      if (state.mobileToken) {
        const params = { deviceToken: state.mobileToken, crudType: "C" } as any;
        try {
          await UserService.updateMobileDeviceInfo(params);
          const systemProperties = { deviceToken: params.deviceToken } as MobileSystemProperties;
          mutations.mobileSystemProperties(state, systemProperties);
        } catch (e) {
          console.log("error : ", e);
        }
      }
    }

    // core.webSocket.init();
    // if (preUser == null) {
    //   core.webSocket.subscribeList = [];
    //
    //   // 알림 데이터 조회
    //   const appData = store.state.app.data;
    //   appData.notificationList = [];
    //
    //   {
    //     try {
    //       const params = {
    //         draw: 0,
    //         start: 0,
    //         length: 200,
    //         orderColumnName: "createdAt",
    //         order: "desc",
    //         searchColumns: {
    //           confirmedAtIsNull: true,
    //         },
    //       } as any;
    //       const response = (await NotificationService.getTable(params)) as any;
    //       response.data.forEach((data) => {
    //         appData.notificationList.push(data);
    //       });
    //     } catch (e) {
    //       console.log(e);
    //     }
    //     // console.log("notification : ", appData.notificationList);
    //   }
    //   const notificationWebSocket = {
    //     userId: user.id,
    //     callback: async (event) => {
    //       //console.log("event : ", event);
    //       const crud = event.crud;
    //       const item = event.item;
    //       (window as any).occurNotification(item);
    //     },
    //   };
    //
    //   const sessionWebSocket = {
    //     userId: user.id,
    //     callback: async (event) => {
    //       //console.log("event : ", event);
    //       // console.log("websocket event! update session reload user");
    //       const user = await store.getters["auth/user"](true);
    //     },
    //   };
    //
    //   const checkWebSocket = () => {
    //     if (core.webSocket.status === Status.CONNECTED) {
    //       // 사용자 알림 구독 처리
    //       core.webSocket.subscribe(SubscribeType.NOTIFICATION, notificationWebSocket, {
    //         connected: async () => {
    //           try {
    //             const params = {
    //               draw: 0,
    //               start: 0,
    //               length: 200,
    //               orderColumnName: "createdAt",
    //               order: "desc",
    //               searchColumns: {
    //                 confirmedAtIsNull: true,
    //               },
    //             } as any;
    //             const response = (await NotificationService.getTable(params)) as any;
    //             appData.notificationList = [];
    //             response.data.forEach((data) => {
    //               appData.notificationList.push(data);
    //             });
    //           } catch (e) {
    //             console.log(e);
    //           }
    //         },
    //         disconnected: () => {},
    //       });
    //
    //       // 사용자 알림 구독 처리
    //       core.webSocket.subscribe(SubscribeType.SESSION, sessionWebSocket, {
    //         connected: () => {},
    //         disconnected: () => {},
    //       });
    //     } else {
    //       setTimeout(() => {
    //         checkWebSocket();
    //       }, 100);
    //     }
    //   };
    //   checkWebSocket();
    // }
  },
  logout(theState) {
    if (theState.user != null) {
      //console.log("logout!");
      // console.log("done");

      theState.sessionCheckTime = new Date();
      theState.user = null;

      // core.webSocket.disconnect();
      if (core.mobile.isApp()) {
        core.mobile.call({ cmd: "cookieSync" });
        // core.mobile.call({ cmd: "refreshUserToken" });
        // mutations.mobileSystemProperties(state, null);
      }
    }
  },

  mobileSystemProperties(state, systemProperties: MobileSystemProperties | null) {
    //console.log('mutations[logout]: ', state.user);
    state.systemProperties = systemProperties;
    // if (systemProperties != null) {
    //   console.log("update system properties : ", JSON.stringify(systemProperties));
    // }
  },
  mobileSetting(state, mobileSetting: MobileSetting | null) {
    //console.log('mutations[logout]: ', state.user);
    state.mobileSetting = mobileSetting;
    if (mobileSetting != null) {
      console.log("update mobileSetting : ", JSON.stringify(mobileSetting));
    }
  },
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
