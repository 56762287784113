
import mixins from "vue-typed-mixins";
import store from "@/store";
import AppBottomBarProps from "@/components/core/AppBottomBarProps.vue";

export default mixins().extend({
  name: "AppBottomBar",
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
  }),
  methods: {
    goBack() {
      this.$router.back();
    },
  },
});
