
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { Role, UserModel } from "@/models/user/user.model";
import core from "@/core";
import store from "@/store";
import MixinsStorage from "@/mixins/single/storage";

export default mixins(MixinsPageForm, MixinsStorage).extend({
  name: "UserWaitApproval",
  data: () => ({
    storageItems: {
      type: "wait-approval",
      items: {},
    },
    user: null as UserModel | null,
    auth: store.state.auth,
  }),
  mounted() {
    this.$nextTick(async () => {
      const user = (this.user = await this.$store.getters["auth/user"]());
      // 사용자 프로필 조회
      const profile = await store.getters["auth/profile"]();
      if (user != null && profile == null) {
        if (user.roles.indexOf(Role.WAIT_USER) > -1) {
          // 프로필 등록 페이지 이동 여부 확인
          await this.storageWait();
          const items = this.getStorageItems();
          if (!items.date) {
            items.date = new Date().getTime();
            this.saveStorageItems();
            console.log("프로필 등록 페이지 이동");
            await core.alert.show({
              title: "알림",
              body: "프로필 등록 페이지로 이동합니다",
            });
            await this.$router.push("/user/me/profile");
          }
        }
      }
    });
  },
  watch: {
    "auth.user"(user) {
      console.log("change auth user");
      if (!this.$store.getters["auth/isWaitUserRole"]) {
        // 페이지 이동 처리
        core.alert.show({
          title: "알림",
          body: "회원가입이 승인되어 페이지를 이동됩니다",
        });
        this.$router.push("/");
      }
    },
  },
  methods: {
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
