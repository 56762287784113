
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import UpdateEvent from "@/models";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { UpdateEventType } from "@/types";
import {
  ConferenceRoomOptionModel,
  ConferenceRoomRequestModel,
  ImageModel,
} from "@/models/conference/conference.model";
import CoreOpenlayersImageMap from "@/core/core-openlayers-image-map";
import { toLonLat } from "ol/proj";
import { Feature } from "ol";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    conference_room_name: "이름",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "ConferenceRoomAddEditModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            type: "add",
            item: null as ConferenceRoomRequestModel | null,
            imageList: [] as ImageModel[],
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    openLayersMap: null as CoreOpenlayersImageMap | null,
    marker: null as Feature | null,
    form: {
      name: "",
    },
    visible: {
      deleteBtn: false,
    },
    selectedImageItem: null as ImageModel | null,
    drawMarker: {
      enable: false,
      x: 0,
      y: 0,
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      console.log("mounted");
      const params = this.properties.params;
      if (params.type === "edit") {
        this.visible.deleteBtn = true;
        const item = params.item;
        if (item != null) {
          const roomItem = item as ConferenceRoomRequestModel;
          console.log("roomItem : ", roomItem);
          this.form.name = item.name;
          if (roomItem.option != null) {
            const option = roomItem.option as ConferenceRoomOptionModel;
            if (option.fileDetailId != null) {
              if (option.x != null && option.y != null) {
                this.drawMarker.enable = true;
                this.drawMarker.x = option.x;
                this.drawMarker.y = option.y;
              }
              params.imageList.some((image) => {
                if (image.id === option.fileDetailId) {
                  this.onSelectImage(image);
                  return true;
                }
              });
            }
          }
        }
      }
    });
  },
  watch: {
    "app.size"() {
      this.windowResize();
    },
    selectedImageItem(selectedImageItem) {
      this.initMap(selectedImageItem);
    },
  },
  methods: {
    windowResize() {
      const app = this.app;
      const size = app.size;
      if (!app.isMobileSize) {
        if (this.$refs.body) {
          const $el = this.$refs.body as any;
          $el.style.minHeight = `${size.height / 2}px`;
        }
      }
    },
    onSelectImage(item) {
      if (this.selectedImageItem != item) {
        this.selectedImageItem = item;
      } else {
        this.selectedImageItem = null;
      }
    },
    async submit() {
      if (await this.validate()) {
        const params = {
          name: this.form.name,
          option: null as ConferenceRoomOptionModel | null,
        };
        if (this.selectedImageItem != null) {
          const option = (params.option = {
            createdAt: "",
            updatedAt: "",
            fileDetailId: this.selectedImageItem.id,
            x: null,
            y: null,
          });
          if (this.marker != null) {
            const geometry = this.marker.getGeometry() as any;
            if (geometry.flatCoordinates) {
              const coordinates = geometry.flatCoordinates;
              option.x = coordinates[0];
              option.y = coordinates[1];
            }
          }
        }
        this.close(UpdateEventType.CONFIRM, params);
      }
    },
    async deleteItem() {
      const item = this.properties.params.item;
      this.close(UpdateEventType.DELETE, item);
    },
    confirm() {
      this.submit();
    },
    initMap(selectedImageItem: ImageModel | null) {
      if (this.openLayersMap != null) {
        this.openLayersMap.destroy();
      }

      if (selectedImageItem == null) {
        this.openLayersMap = null;
        this.marker = null;
      } else {
        const openLayersMap = (this.openLayersMap = new CoreOpenlayersImageMap({
          map: {
            element: this.$refs.map,
            image: {
              url: selectedImageItem.uri,
              size: {
                width: selectedImageItem.width,
                height: selectedImageItem.height,
              },
            },
            zoom: 1,
            minZoom: 1,
            maxZoom: 10,
            enable: {
              rotate: false,
              zoom: true,
            },
          },
          callback: {
            click: this.mapClickEvent,
          },
        }));
        if (this.drawMarker.enable) {
          this.drawMarker.enable = false;
          this.marker = openLayersMap.addMarker({
            name: "position",
            position: {
              x: this.drawMarker.x,
              y: this.drawMarker.y,
            },
          });
        }
      }
    },
    mapClickEvent(e) {
      const openLayersMap = this.openLayersMap;
      if (openLayersMap) {
        // const latLng = openLayersMap.toLatLng(e.coordinate);
        const coordinate = e.coordinate;
        console.log("coordinate : ", coordinate);
        if (this.marker == null) {
          this.marker = openLayersMap.addMarker({
            name: "position",
            position: {
              x: coordinate[0],
              y: coordinate[1],
            },
          });
        } else {
          openLayersMap.moveMarker(this.marker, coordinate[0], coordinate[1]);
        }
      }
    },
  },
});
