
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsStorage from "@/mixins/single/storage";
import MixinsTable from "@/mixins/single/table";
import core from "@/core";
import store from "@/store";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";
import { UpdateEventType } from "@/types";
import UserListItemComponent from "@/components/user/ListItemComponent.vue";
import { cloneDeep } from "lodash";
import UserMgmtService from "@/services/user/user-mgmt.service";
import UserService from "@/services/user/user.service";
import { Role } from "@/models/user/user.model";
import MixinsScroll from "@/mixins/single/scroll";

export default mixins(MixinsPageForm, MixinsStorage, MixinsTable, MixinsScroll).extend({
  name: "MgmtUserSearch",
  components: { UserListItemComponent },
  data: () => ({
    history: "search",
    storageItems: {
      type: "mgmt-user-search",
    },
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    historyItems: [] as any,
    historyFilterItems: [] as any,
    submitSearchKeyword: null as string | null,
    searchKeyword: null as string | null,
    searchKeywordFocus: false,
    searchKeywordFocusTime: new Date().getTime(),
    searchType: "keyword", // keyword, detail
    preViewType: "",
    viewType: "history", // history, detail, result
    keywordPromise: {
      wait: null as any,
      resolve: null as any,
    },
    detailSearch: {
      usernameLike: "",
      nameLike: "",
      emailLike: "",
      role: "",
    },
    roleList: [] as any,
  }),

  mounted() {
    this.$nextTick(async () => {
      const roleList = this.roleList;
      [Role.WAIT_USER, Role.USER, Role.MANAGER, Role.ADMIN].forEach((role) => {
        roleList.push({
          id: role,
          text: UserService.roleToText(role),
        });
      });

      await this.storageWait();
      const items = this.getStorageItems(this.history);
      if (items != null) {
        this.historyItems = items;
      } else {
        this.setStorageItem(this.history, this.historyItems);
      }

      // 테이블 초기화
      this.initTable({
        service: UserMgmtService,
        serviceFunctionKey: "getTable",
        itemHeight: 80,
      });

      this.changedSearchKeyword(this.searchKeyword);
      this.keywordPromise.wait = new Promise((resolve, reject) => {
        this.keywordPromise.resolve = resolve;
      });

      const vm = this as any;
      const routerBeforeTask = store.state.app.routerBeforeTask;
      const routerBeforeTaskItem = new RouterBeforeTaskItem(vm.$vnode.tag, () => {
        //console.log("viewType : ", vm.viewType, ", searchType : ", this.searchType);
        if (vm.searchType === "detail") {
          if (vm.preViewType === "result") {
            vm.viewType = "result";
          } else {
            vm.searchType = "keyword";
          }
          return false;
        }
        return true;
      });
      routerBeforeTask.pushPage(routerBeforeTaskItem);

      this.moveFocus("searchKeyword");
      this.focusIn();
    });
  },

  computed: {
    tableDataList() {
      return this.table.response.data;
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },

  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      this.updatePreVmEvent(event.result, event.item);

      if (event.result === UpdateEventType.UPDATE) {
        this.addTableItem("id", event.item);
      } else if (event.result === UpdateEventType.DELETE) {
        //console.log("event : ", event);
        this.deleteTableItem("id", event.item);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },

  watch: {
    "scroll.scrollTop"(scrollTop) {
      // console.log("scrollTop : ", scrollTop);
      this.scrollTop = scrollTop;
    },
    searchKeyword(val) {
      this.changedSearchKeyword(val);
      //console.log("changed searchKeyword");
      this.keywordPromise.resolve();
    },
    searchType(type) {
      if (type === "keyword") {
        this.viewType = "history";
      } else if (type === "detail") {
        this.viewType = "detail";
      } else {
        console.log("Unknown Type : ", type);
      }
    },
    viewType(val, prev) {
      this.preViewType = prev;

      // viewType 변경시, 스크롤바 위로 이동
      const $el = this.$refs.body as any;
      if ($el != null) {
        $el.scrollTop = 0;
      }
    },
  },
  methods: {
    getHistoryDate(item: any) {
      return this.dateElapsedTimeFormat(
        core.date.instance(new Date(item.date)).format("YYYY-MM-DD HH:mm:ss")
      );
    },
    isSearch() {
      return this.table.request.draw > 0;
    },
    isResultEmpty() {
      return this.table.request.draw > 0 && this.table.response.data.length == 0;
    },
    changedSearchKeyword(searchKeyword) {
      const isBlank = this.isBlank(searchKeyword);
      const filterItems = [] as any;

      this.historyItems.some((item: any) => {
        if (isBlank || item.keyword.indexOf(searchKeyword) > -1) {
          filterItems.push(item);
        }
        if (filterItems.length === 20) {
          return true;
        }
      });
      this.historyFilterItems = filterItems;
      if (filterItems.length > 0 && this.searchKeywordFocus) {
        // TODO : 이력정보 표시?
        //this.visibleHistory = true;
      }
    },
    focusIn() {
      this.searchKeywordFocus = true;
      this.searchKeywordFocusTime = new Date().getTime();
      this.viewType = "history";
    },
    focusOut() {
      if (new Date().getTime() - this.searchKeywordFocusTime > 100) {
        this.searchKeywordFocus = false;
      }
    },
    submit() {
      (this.$refs.searchKeyword as any).blur();
      this.searchKeywordFocus = false;

      const searchKeyword = this.searchKeyword as string;
      if (this.isNotBlank(searchKeyword)) {
        this.viewType = "result";

        this.submitSearchKeyword = searchKeyword;
        //console.log("submit searchKeyword : ", this.searchKeyword);

        // 키워드 스토리지 동일 항목 제외, 최대 100개 저장
        this.historyItems.some((item: any, index) => {
          if (item.keyword === searchKeyword) {
            this.historyItems.splice(index, 1);
            return true;
          }
        });

        const diffLength = this.historyItems.length - 99;
        if (diffLength > 0) {
          for (let i = 0; i < diffLength; i++) {
            this.historyItems.splice(this.historyItems.length - 1, 1);
          }
        }

        this.historyItems.unshift({
          keyword: searchKeyword,
          date: new Date().getTime(),
        });
        this.saveStorageItems();

        const searchColumns = this.keywordToSearchColumns(searchKeyword);
        this.clearTable();
        this.loadTable(searchColumns);
      } else {
        core.alert.show({
          title: "알림",
          body: "키워드를 입력하세요!",
        });
      }
    },

    async submitDetail() {
      if (await this.validate()) {
        const params = cloneDeep(this.detailSearch);
        for (const key of Object.keys(params)) {
          const value = params[key];
          if (this.isBlank(value)) {
            delete params[key];
          } else if (key === "phone") {
            params[key] = params[key].replaceAll("-", "");
          }
        }
        if (Object.keys(params).length === 0) {
          await core.alert.show({
            title: "알림",
            body: "입력된 조건이 1개 이상이어야 합니다.",
          });
        } else {
          //console.log("params : ", params);
          this.viewType = "result";
          this.clearTable();
          this.loadTable(params);
        }
      }
    },
    async selectHistory(item: any) {
      //console.log("selectHistory : ", item);

      const changedSearchKeyword = this.searchKeyword !== item.keyword;

      if (changedSearchKeyword) {
        this.searchKeyword = item.keyword;
        await this.keywordPromise.wait;
      }

      if (this.submitSearchKeyword !== item.keyword) {
        if (!changedSearchKeyword) {
          this.changedSearchKeyword(this.searchKeyword);
        }
        this.submit();
      } else {
        this.changedSearchKeyword(this.searchKeyword);
        if (this.viewType === "history") {
          this.viewType = "result";
        }
      }
    },

    keywordToSearchColumns(searchKeyword: string) {
      const searchColumns = {} as any;

      // 1. 이메일 형식 분류
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        const result = core.utils.format.textToEmail(searchKeyword) as any;
        // console.log("phoneResult : ", phoneResult);
        if (result != null) {
          console.log(`replace content ${searchKeyword} -> ${result.replaceContent}`);
          searchKeyword = result.replaceContent;

          if (result.list.length > 1) {
            searchColumns.emailList = result.list.join(",");
          } else {
            searchColumns.emailLike = result.list[0];
          }
        }
      }

      // 2. 이름
      if (core.utils.validate.isNotBlank(searchKeyword)) {
        searchColumns.nameLike = searchKeyword;
      }

      return searchColumns;
    },
  },
});
