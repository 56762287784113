
import store from "@/store";
import core from "@/core";
import UserService from "@/services/user/user.service";
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";

export default mixins(MixinsPageForm).extend({
  name: "UserMyPassword",
  components: {},
  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      currentPassword: "",
      newPassword: "",
      newConfirmPassword: "",
    },
    formConvertField: {
      currentPassword: "user_currentPassword",
      newPassword: "user_password",
      newConfirmPassword: "user_confirmPassword",
    },
  }),
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show();
        this.processing = true;
        try {
          const form = this.form;
          await UserService.updatePassword(
            form.currentPassword,
            form.newPassword,
            form.newConfirmPassword
          );
          core.loader.hide();

          await core.alert.show({
            title: "알림",
            body: "비밀번호가 변경되었습니다.",
          });
          await this.$router.back();
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
        this.processing = false;
      }
    },
  },
});
