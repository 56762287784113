import Vue from "vue";
import core from "@/core";

export default Vue.extend({
  data: () => ({
    scrollTop: 0,
    table: {
      body: null as any,
      ready: false,
      loading: false,
      firstLoading: true,
      event: {
        reloadFlag: false,
        nextLoadFlag: false,
      },
      itemHeight: 48,
      request: {
        draw: 0,
        start: 0,
        length: 10,
        orderColumnName: "createdAt",
        order: "desc",
        searchColumns: {} as any,
      },
      response: {
        draw: 0,
        recordsFiltered: 0,
        recordsTotal: 0,
        data: [] as any,
      },
      service: null as any,
      serviceFunctionKey: null as any,
      scrollEvent: null as any,
      defaultColumns: [] as any,
    },
  }),
  created() {
    this.table.request.length = 10;
  },
  computed: {
    tableDataList() {
      return this.table.response.data;
    },
  },
  watch: {
    scrollTop(val) {
      const el = this.table.body;
      if (el != null) {
        this.changeScrollTop(val, el.scrollHeight, el.clientHeight);
      } else {
        console.log("not found el : ", el);
      }
    },
    "table.event.reloadFlag"() {
      if (this.table.event.reloadFlag) {
        //console.log("reload table, ready : ", this.table.ready);
        this.table.event.reloadFlag = false;
        if (this.table.ready) {
          this.getTable();
        }
      }
    },
    "table.event.nextLoadFlag"() {
      if (this.table.event.nextLoadFlag) {
        if (this.table.ready) {
          this.getTable(this.table.response.data.length);
        }
      }
    },
  },
  methods: {
    initTable(params: {
      service?: any;
      serviceFunctionKey?: string;
      itemHeight: number;
      requestLength?: number;
    }) {
      //console.log("params: ", params);
      this.table.itemHeight = params.itemHeight;
      if (params.requestLength != null) {
        this.table.request.length = params.requestLength;
      } else {
        this.table.request.length = Math.ceil(window.innerHeight / params.itemHeight + 5);
      }
      // console.log("table request length : ", this.table.request.length);
      // this.table.getListFunc = params.getListFunc;
      this.table.service = params.service;
      this.table.serviceFunctionKey = params.serviceFunctionKey;
    },
    loadTable(searchColumns?: any) {
      this.table.ready = true;
      this.table.event.reloadFlag = true;
      if (searchColumns != null) {
        this.table.request.searchColumns = searchColumns;
      }
    },
    clearTable() {
      this.table.request.draw = 0;
      this.table.request.start = 0;
      this.table.response.recordsFiltered = 0;
      this.table.response.recordsTotal = 0;
      this.table.response.data = [] as any;
    },
    reloadTable(searchColumns?: any) {
      if (this.table.ready) {
        if (searchColumns != null) {
          this.table.request.searchColumns = searchColumns;
        }
        this.table.event.reloadFlag = true;
      }
    },
    changeScrollTop(scrollTop: number, scrollHeight: number, clientHeight: number) {
      const request = this.table.request;
      const response = this.table.response;
      const event = this.table.event;

      if (event.nextLoadFlag) return;
      else if (request.start + request.length >= response.recordsTotal) return;

      // 이벤트 처리 높이
      const eventHeight = Number(clientHeight / 5);

      // 스크롤 최대 높이
      const scrollMaxTop = scrollHeight - clientHeight;
      // const scrollPercentage = (100 * scrollTop) / (scrollHeight - clientHeight);
      // console.log("scrollTop : ", scrollTop);
      // console.log("scrollHeight : ", scrollHeight);
      // console.log("clientHeight : ", clientHeight);
      if (scrollMaxTop - scrollTop < eventHeight) {
        //console.log("scrollPercentage : " + scrollPercentage);
        event.nextLoadFlag = true;
      }
    },
    async getTable(start?: number) {
      if (start == null) start = 0;
      if (start === 0) this.table.response.data = [] as any;
      //console.log("getTable start : ", start);
      this.table.request.draw = this.table.request.draw + 1;
      this.table.request.start = start;

      this.table.loading = true;

      try {
        const response = (await this.table.service[this.table.serviceFunctionKey](
          this.table.request
        )) as any;
        this.table.event.nextLoadFlag = false;

        const defaultColumns = this.table.defaultColumns;
        response.data.forEach((data: any) => {
          if (defaultColumns != null) {
            defaultColumns.forEach((key) => {
              if (data[key] == null) {
                data[key] = null;
              }
            });
          }
          this.table.response.data.push(data);
        });
        this.table.response.recordsFiltered = response.recordsFiltered;
        this.table.response.recordsTotal = response.recordsTotal;
        this.table.response.draw = response.draw;
        this.table.loading = false;
        this.table.firstLoading = false;
        core.loader.hide();
      } catch (e) {
        this.table.event.nextLoadFlag = false;
        console.log(e);
      }
    },
    updateTableItem(key: string, item: any) {
      const data = this.table.response.data;
      const length = data.length;
      for (let i = 0; i < length; i++) {
        const _item = data[i];
        if (_item[key] == item[key]) {
          Object.assign(_item, item);
          return true;
        }
      }
      return false;
    },
    addTableItem(key: string, item: any) {
      const data = this.table.response.data;
      const length = data.length;
      for (let i = 0; i < length; i++) {
        const _item = data[i];
        if (_item[key] == item[key]) {
          if (this.table.request.orderColumnName !== "updatedAt") {
            // core.utils.copy(item, _item);
            Object.assign(_item, item);
            return false;
          } else {
            this.table.response.data.splice(i, 1);
            break;
          }
        }
      }
      this.table.response.data.unshift(item);
      return true;
    },
    deleteTableItem(key: string, item: any) {
      const data = this.table.response.data;
      const length = data.length;
      for (let i = 0; i < length; i++) {
        const _item = data[i];
        if (_item[key] == item[key]) {
          //console.log("delete : ", item);
          data.splice(i, 1);
          return;
        }
      }
    },
  },
});
