export default [
  { color: "teal", hex: "#4CAF50", text: "그린", dark: true },
  { color: "cyan", hex: "#00BCD4", text: "사이언", dark: true },
  { color: "light-blue", hex: "#03A9F4", text: "스카이블루", dark: true },
  { color: "indigo", hex: "#3F51B5", text: "인디고", dark: true },
  { color: "brown", hex: "#795548", text: "브라운", dark: true },
  { color: "black", hex: "#000000", text: "블랙", dark: true },
  { color: "red", hex: "#F44336", text: "레드", dark: true },
  { color: "pink", hex: "#E91E63", text: "핑크", dark: true },
  { color: "purple", hex: "#9C27B0", text: "퍼플", dark: true },
  { color: "deep-purple", hex: "#673AB7", text: "딥 퍼플", dark: true },
  { color: "orange", hex: "#FF9800", text: "오렌지", dark: true },
  { color: "deep-orange", hex: "#FF5722", text: "딥 오렌지", dark: true },
  { color: "blue-grey", hex: "#607D8B", text: "블루 그레이", dark: true },
];
