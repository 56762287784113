import { IdModel, IdUpdateModel, UpdateModel } from "@/models/core/type.model";
import { ConferenceUserScheduleModel } from "@/models/conference/conference-user-schedule.model";

export enum ConferenceStatusType {
  WAIT = "WAIT",
  ACTIVE = "ACTIVE",
  END = "END",
}

export interface ConferenceModel extends IdUpdateModel {
  name: string;
  startDate: string;
  endDate: string;
  address1: string;
  address2: string;
  latitude: number;
  longitude: number;
  status: ConferenceStatusType;
}

export interface ConferencePresentationAreaModel extends IdUpdateModel {
  name: string;

  conferenceId: number;
}

export interface ConferenceRoomOptionModel extends UpdateModel {
  fileDetailId: number;
  x: number | null;
  y: number | null;
}

export interface ConferenceRoomModel extends ConferenceRoomRequestModel {
  createdAt: string;
  updatedAt: string;
}

export interface ConferenceRoomRequestModel {
  id?: number;
  index: number;
  name: string;
  option?: ConferenceRoomOptionModel;
}

export interface ConferenceRoomImageModel extends IdUpdateModel {
  conferenceId: number;
  fileDetailId: number;
  image: ImageModel;
  no: number;
}

export interface ImageModel extends IdModel {
  width: number;
  height: number;
  thumbnailUri: string;
  uri: string;
}

export interface ConferenceScheduleModel extends IdUpdateModel {
  startAt: string;
  endAt: string;
  conferenceId: number;
  conferenceRoomId: number | null;
  conferenceRoom: ConferenceRoomModel | null;
  title: string;
  presentationArea: string;
  // presenter: string;
  // 제1저자
  author1: string;
  // 제2저자
  author2: string | null;
  // 제3저자
  author3: string | null;
  // 제4저자
  author4: string | null;
  // 제5저자
  author5: string | null;
  // 제6저자
  author6: string | null;
  // 제7저자
  author7: string | null;
  // 제8저자
  author8: string | null;
  // 논문초록
  abstract: string | null;
}

export interface ConferenceScheduleDisplayModel extends ConferenceScheduleModel {
  userSchedule: ConferenceUserScheduleModel | null;
}

// export interface ConferenceScheduleDisplayModel extends IdUpdateModel {
//   startAt: string;
//   endAt: string;
//   conferenceId: number;
//   conferenceRoomId: number;
//   conferenceRoom: ConferenceRoomModel | null;
//
//   title: string;
//   presentationArea: string;
//   presenter: string;
// }
