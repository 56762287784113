
import Vue from "vue";
import store from "@/store";
import AppBar from "@/components/core/AppBar.vue";
import AlertModal from "@/modals/core/AlertModal.vue";
import { LayoutType, RouteMeta } from "@/router";
import core from "@/core";
import { cloneDeep } from "lodash";
import AppBottomBar from "@/components/core/AppBottomBar.vue";
import UpdateEvent from "@/models";
import { AppBarMenu, MobileSystemProperties, UpdateEventType } from "@/types";
import { TYPE } from "vue-toastification";
import { Route } from "vue-router";
import NavigationDrawer from "@/components/core/NavigationDrawer.vue";
import PublicUserScheduleService from "@/services/conference/public-user-schedule.service";

export default Vue.extend({
  name: "App",
  components: { NavigationDrawer, AppBottomBar, AlertModal },
  data: () => ({
    transitionName: "forward",
    app: store.state.app,
    auth: store.state.auth,
    prop: {
      layoutList: null as LayoutType[] | null,
      appBarMenu: null as AppBarMenu | null,
    },
    redirect: {
      path: null as string | null,
      interval: null as number | null,
    },
    updateEvent: null as UpdateEvent | null,
    visible: {
      appBar: false,
      nav: false,
      bottomBar: false,
    },
  }),

  computed: {
    LayoutType() {
      return LayoutType;
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
  },
  mounted() {
    //console.log("app mounted");
    (window as any).updateMobileSystemProperties = this.updateMobileSystemProperties;
    (window as any).updateMobileSetting = this.updateMobileSetting;
    (window as any).socialLogin = this.socialLogin;
    (window as any).mobileRedirectPath = this.mobileRedirectPath;
    (window as any).showModal = this.showModal;
    (window as any).showToast = this.showToast;
    (window as any).chnageMeta = this.changeMeta;
    (window as any).occurNotification = this.occurNotification;
    (window as any).webSocketDisconnect = () => {
      // core.webSocket.disconnect();
    };
    // (window as any).changedUserCompany = this.changedUserCompany;

    const windowResize = this.windowResize;
    if (visualViewport != null) {
      visualViewport.addEventListener("resize", () => {
        windowResize();
      });
    } else {
      window.addEventListener("resize", () => {
        windowResize();
      });
    }
    windowResize();
    document.documentElement.style.setProperty("--toolbar-height", `0px`);
    this.app.showNav = !this.app.isMobileSize;

    const platform = core.utils.platform();
    if (platform === "android" || platform === "ios") {
      this.app.isMobile = true;
    }
    this.app.isApp = core.mobile.isApp();
    if (this.app.isApp) {
      core.mobile.call({ cmd: "getSystemProperties" });
    }
    (this as any).$eventBus.$on("updateEvent", (event: UpdateEvent) => {
      // console.log("call updateEvent : ", event);
      this.updateEvent = event;
    });

    this.changeRoute(this.$route);
    this.updateMobileSystemProperties(JSON.stringify({ deviceToken: "test" }));
  },
  watch: {
    async "auth.uuid"(uuid) {
      if (uuid != null && uuid.length > 0) {
        try {
          const userScheduleData = this.app.data.userSchedule;
          const list = await PublicUserScheduleService.getList(undefined, {
            uuid: uuid,
          });
          list.forEach((userSchedule) => {
            ["alarmTimeMin", "alarmAt"].forEach((key) => {
              if (userSchedule[key] == null) {
                userSchedule[key] = null;
              }
            });
          });
          userScheduleData.list = list;
          userScheduleData.ready = true;
        } catch (e) {
          console.log(e);
        }
      }
    },
    $route(to, from) {
      if (to.params["stackKey-dir"] === "forward") {
        this.transitionName = "forward";
      } else {
        this.transitionName = "back";
        const event = this.updateEvent;
        if (event != null) {
          this.updateEvent = null;
          if (event.result == UpdateEventType.PAGE) {
            const item = event.item;

            const params = {} as any;
            if (item.path) {
              params.path = item.path;
            }
            if (item.query) {
              params.query = item.query;
            }

            if (to.path !== params.path) {
              // console.log("from page : ", from.path);
              // console.log("to page : ", to.path);
              // console.log("move page : ", item.path);
              this.$router.push(params);
            }
          } else {
            console.log("unknown event type : ", event);
          }
        }
      }
      const meta = to.meta as RouteMeta;
      // console.log("meta : ", meta);
      this.changeMeta(meta);
      this.app.path = this.$route.path;

      this.windowResize();
      this.changeRoute(this.$route);
    },
    "redirect.path"(path) {
      const redirect = this.redirect;
      if (redirect.path != null && redirect.interval == null) {
        const auth = this.$store.state.auth;
        const router = this.$router;
        const vm = this as any;

        redirect.interval = setInterval(() => {
          if (auth.ready) {
            const path = redirect.path as string;
            redirect.path = null;
            // console.log("redirect : ", path);
            try {
              router.push({
                path: path,
              });
            } catch (e) {
              console.log(e);
            }
          }

          if (redirect.path == null || vm._isDestroyed) {
            clearInterval(redirect.interval as number);
            redirect.interval = null;
          }
        }, 200);
      }
    },
  },
  methods: {
    changeRoute(route: Route) {
      const type = route.path.replace("/", "");
      if (this.app.bottomNav !== type) {
        this.app.bottomNav = type;
      }
    },
    hasLayout(...paramLayoutTypes) {
      if (this.prop.layoutList != null) {
        let exists = false;
        paramLayoutTypes.some((paramLayoutType) => {
          (this.prop.layoutList as LayoutType[]).some((layoutType) => {
            if (paramLayoutType === layoutType) {
              exists = true;
              return exists;
            }
          });
          if (exists) {
            return exists;
          }
        });
        return exists;
      }
      return false;
    },
    changeMeta(meta: any) {
      this.prop.layoutList = meta.layoutList;
      this.prop.appBarMenu = meta.appBarMenu;

      this.visible.appBar = this.hasLayout(
        LayoutType.APP_BAR,
        LayoutType.APP_BAR_NAV,
        LayoutType.APP_BAR_BACK
      );
      this.visible.bottomBar = this.hasLayout(LayoutType.APP_BOTTOM_BAR);
      this.visible.nav = this.hasLayout(LayoutType.NAV);
    },
    windowResize() {
      // console.log(`resize width '${window.innerWidth}', '${window.innerHeight}'`);
      // console.log(`resize width '${window.outerWidth}', '${window.outerHeight}'`);
      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
        bodyWidth: window.innerWidth,
      };

      // document.body.style.minHeight = this.app.size.height + "px";
      document.documentElement.style.setProperty("--vh", `${size.height * 0.01}px`);
      if (size.width < 960) {
        document.documentElement.style.setProperty("--app-bar-height", `56px`);
      } else {
        document.documentElement.style.setProperty("--app-bar-height", `64px`);
      }

      this.app.isMobileSize = size.width < 1264;
      if (!this.app.isMobileSize) {
        // 현재 페이지에 nav 존재 여부
        if (
          this.app.showNav &&
          this.prop &&
          this.prop.layoutList &&
          this.prop.layoutList.indexOf(LayoutType.NAV) > -1
        ) {
          size.bodyWidth = window.innerWidth - 300;
        }
      }
      this.app.size = size;
    },
    occurNotification: function (item: any) {
      const path = this.$route.path;

      const appData = store.state.app.data;
      // const notificationList = cloneDeep(appData.notificationList);
      const notificationList = appData.notificationList;
      let exists = false;
      let existsIndex = 0;
      for (let i = 0; i < notificationList.length; i++) {
        const _item = notificationList[i];
        if (_item.id === item.id) {
          exists = true;
          existsIndex = i;
          break;
        }
      }
      if (!exists) {
        notificationList.push(item);
        // console.log("occur notification : ", item);
        this.showToast(item.content);
      } else if (item.confirmedAt != null) {
        notificationList.splice(existsIndex, 1);
        // console.log("length : ", notificationList.length);
      }
    },
    showModal: function (params) {
      return core.alert.show(params);
    },
    showToast: function (content: string) {
      core.toast.show({
        type: TYPE.INFO,
        content: content,
      });
    },
    mobileRedirectPath: function (path: string) {
      //console.log("mobileRedirectPath : ", path);
      if (path != null && path.length > 0) {
        this.redirect.path = path;
      }
    },
    updateMobileSystemProperties: function (strSystemProperties: string) {
      // console.log("updateMobileSystemProperties : ", strSystemProperties);
      const systemProperties = JSON.parse(strSystemProperties) as MobileSystemProperties;
      // console.log("systemProperties : ", systemProperties);
      if (systemProperties.deviceToken) {
        this.$store.dispatch("auth/updateMobileSystemProperties", systemProperties);
      } else {
        setTimeout(() => {
          core.mobile.call({ cmd: "getSystemProperties" });
        }, 2000);
      }
    },
    updateMobileSetting: function (strMobileSetting: string) {
      // console.log("strSettings : ", strSettings);
      const mobileSetting = JSON.parse(strMobileSetting);

      this.$store.dispatch("auth/updateMobileSetting", mobileSetting);
    },
    socialLogin: function (strParam: string, addProvider: boolean | null) {
      //console.log(strParam);
      if (addProvider == null) addProvider = false;
      const token = JSON.parse(strParam);
      core.loader.show("로그인 중...");
      const params = {
        registrationId: "kakao",
        accessToken: token.accessToken,
        rememberMe: true,
      } as any;

      if (addProvider) {
        params.addProvider = addProvider;
      }

      this.$store
        .dispatch("auth/socialLogin", params)
        .then(async (data) => {
          if (data.result) {
            console.log("call reload");
            window.location.reload();
          } else {
            //console.log(data);
            if (data.type === "addProvider") {
              core.loader.hide();
              const result = await core.alert.show({
                title: "확인",
                body: "이미 가입된 계정이 존재합니다.<br>소셜 로그인 정보를 추가하시겠습니까?",
                showCancelButton: true,
                cancelButtonText: "아니오",
                confirmButtonText: "예",
              });

              if (result === "confirm") {
                this.socialLogin(strParam, true);
              } else {
                await core.alert.show({
                  title: "알림",
                  body: "가입된 계정 정보로 로그인 해주세요",
                });
              }
            } else {
              core.loader.hide();

              // 가입되지 않은 계정 회원가입 페이지로 이동
              const params = cloneDeep(data.socialUser) as any;
              params.token = data.token;

              const query = this.$route.query;
              // console.log("query : ", query);
              const keys = Object.keys(query);
              for (const key of keys) {
                if (!params[key]) {
                  params[key] = query[key];
                }
              }
              //console.log("params : ", params);
              await this.$router.push({
                path: "/register",
                query: params,
              });
            }
          }
        })
        .catch((reason) => {
          core.loader.hide();
        });
    },
  },
});
