import { TYPE } from "vue-toastification/src/ts/constants";

export interface Toast {
  type?: TYPE;
  content: string;
}

export default class CoreToast {
  private readonly toast: any;
  private toastShow = false;

  constructor(toast: any) {
    this.toast = toast;
  }

  show(params: Toast) {
    if (this.toast == null) {
      console.log("toast is null!");
      return;
    }
    this.toast.clear();

    if (params.type == null) params.type = TYPE.INFO;
    if (params.type === "error") {
      this.toastShow = true;
      this.toast.error(params.content, {
        id: "toast",
        onClose: () => {
          this.toastShow = false;
        },
      });
    } else if (params.type === "warning") {
      this.toastShow = true;
      this.toast.warning(params.content, {
        id: "toast",
        onClose: () => {
          this.toastShow = false;
        },
      });
    } else if (params.type === "success") {
      this.toastShow = true;
      this.toast.success(params.content, {
        id: "toast",
        onClose: () => {
          this.toastShow = false;
        },
      });
    } else {
      this.toastShow = true;
      this.toast.info(params.content, {
        id: "toast",
        onClose: () => {
          this.toastShow = false;
        },
      });
    }
  }

  isShow() {
    return this.toastShow;
  }

  hide() {
    this.toast.clear();
  }
}
