
import mixins from "vue-typed-mixins";
import TopToolbarProps from "@/components/core/TopToolbarProps.vue";

export default mixins(TopToolbarProps).extend({
  name: "TopToolbar",
  data: () => ({}),
  mounted() {
    this.$nextTick(() => {
      const vue = this.$refs.toolbar as any;
      if (vue != null) {
        // console.log(vue.$el.clientHeight);
        this.$emit("update:height", vue.$el.clientHeight);
      }
    });
  },
});
