import AbstractTableService from "@/services/core/abstract-table.service";
import { ConferenceUserScheduleModel } from "@/models/conference/conference-user-schedule.model";
import core from "@/core";

class PublicUserScheduleService extends AbstractTableService<ConferenceUserScheduleModel> {
  constructor() {
    super("/api/v1/p/conference/user-schedule", "userSchedule");
  }

  update(params: any, replaceParams?: [string]): Promise<ConferenceUserScheduleModel> {
    const url = this.getUrl(replaceParams);
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`${url}`, strJson)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new PublicUserScheduleService();
