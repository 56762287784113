
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { Role } from "@/models/user/user.model";

export interface RoleItem {
  id: string;
  name: string;
  checked: boolean;
}

export default mixins(MixinsModal).extend({
  name: "RoleEditModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            roles: null as null | Role[],
          },
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    roleList: [] as RoleItem[],
  }),
  mounted() {
    this.$nextTick(() => {
      const roles = this.properties.params.roles;
      if (roles) {
        this.roleList.push({
          id: Role.ADMIN,
          name: "관리자",
          checked: roles.indexOf(Role.ADMIN) > -1,
        });
        this.roleList.push({
          id: Role.MANAGER,
          name: "매니저",
          checked: roles.indexOf(Role.MANAGER) > -1,
        });
        this.roleList.push({
          id: Role.USER,
          name: "사용자",
          checked: roles.indexOf(Role.USER) > -1,
        });
        this.roleList.push({
          id: Role.WAIT_USER,
          name: "가입대기",
          checked: roles.indexOf(Role.WAIT_USER) > -1,
        });
      } else {
        console.log("invalid param : ", roles);
        this.close(UpdateEventType.CANCEL);
      }
    });
  },
  methods: {
    select(item) {
      // console.log("item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
  },
});
