import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AppState, RootState } from "@/types";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";
import { NotificationModel } from "@/models/user/notification.model";
import { ConferenceUserScheduleModel } from "@/models/conference/conference-user-schedule.model";

const state: AppState = {
  version: process.env.VUE_APP_VERSION || "0",
  name: Constant.appName,
  size: { width: 0, height: 0 },
  routerBeforeTask: new RouterBeforeTask(),
  modal: {
    alert: {
      visible: false,
      title: "알림",
      body: "내용",
      showCancelButton: false,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
      promise: {
        resolve: null,
        reject: null,
      },
      allowBackCloseEvent: true,
    },
  },
  showNav: true,
  bottomNav: "",
  path: "",
  isApp: false,
  isMobile: false,
  isMobileSize: false,
  webSocketConnected: false,
  data: {
    ready: false,
    notificationList: [] as NotificationModel[],
    userSchedule: {
      ready: false,
      first: true,
      list: [] as ConferenceUserScheduleModel[],
    },
  },
};

export const getters: GetterTree<AppState, RootState> = {
  webSocketConnected: (theState: AppState) => {
    return theState.webSocketConnected;
  },
};

export const actions: ActionTree<AppState, RootState> = {
  webSocketConnected({ commit }, data) {
    commit("webSocketConnected", data);
  },
  webSocketDisconnect() {
    // core.webSocket.disconnect();
  },
};

const mutations: MutationTree<AppState> = {
  async webSocketConnected(theState, data) {
    // const connected = data.connected;
    // const subscribeList: Subscribe[] | null = data.subscribeList;
    //
    // const preWebSocketConnected = theState.webSocketConnected;
    // theState.webSocketConnected = connected;
    //
    // if (preWebSocketConnected != connected) {
    //   // console.log("change web-socket connected : ", connected);
    //
    //   if (connected) {
    //     // console.log("subscribeList : ", subscribeList);
    //
    //     if (subscribeList != null) {
    //       subscribeList.forEach((subscribe) => {
    //         if (subscribe.type != null) {
    //           core.webSocket.subscribe(subscribe.type, subscribe.item, subscribe.callback);
    //         }
    //       });
    //     }
    //     // theState.data.ready = true;
    //   }
    // }
  },
};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
